import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'

import Stage from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/stage.jpg'
import PressFitting from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/PressFitting.jpg'
import InterlockFitting from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/InterlockFitting.jpg'
import SpecialFitting from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/SpecialFitting.jpg'
import FittingsForExtreme from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/FittingsForExtreme.jpg'
import FittingForLowPressure from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/FittingForLowPressure.jpg'
import EasyToInstall from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/EasyToInstall.jpg'
import TheWide from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/TheWide.jpg'
import PreAssembled from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/PreAssembled.jpg'
import HydraulicHoses from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/HydraulicHoses.jpg'
import RightConnections from '../../../assets/Products/HydraulicConnectionTechnology/HoseFitting/RightConnections.jpg'

const HoseFittings = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Maximum performance",
            description: "Every fitting is perfectly matched to the hose and thus offers the highest protection against leaks.",
            image: imgMark
        },
        {
            title: "Comprehensive service",
            description: "All fittings are available individually. We are also delighted to supply them fitted to hose lines.",
            image: imgMark
        },
        {
            title: "Maximum safety",
            description: "Extensive tests ensure the functional capability of the fittings up to 500 bar.",
            image: imgMark
        },
        {
            title: "Excellent corrosion protection",
            description: "Thanks to a zinc-nickel coating, the press fittings are resistant to red rust for 1,200 h.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    const Data1 = {
        title: 'Press fittings for medium- and high-pressure applications',
        description: 'For standard applications in medium- and high-pressure environments, we offer an extensive range of crimping ferrules and hose nipples with metric, UNF, NPT and imperial connecting threads, SAE flange heads and other flange systems. In addition to the generally quicker to assemble no-skive crimp ferrules in which the sleeve is pushed over the unpeeled hose and then crimped, the HANSA-FLEX online shop also has hose fittings for braided hoses with skive crimp ferrules up to a nominal size of DN 76.',
        image: PressFitting
    }

    const Data2 = {
        title: 'Interlock hose fittings with pull-out protection',
        description: 'To ensure a safe connection between hose and fitting, even under extreme loads and high pressures up to 500 bar, HANSA-FLEX offers a wide range of interlock fittings up to a nominal size of DN 50 as a system solution with pull-out protection. The inner and outer layers of the hose are peeled (double skive) to allow the crimp nipple to achieve a highly effective positive fit with the hose and the fitting. The loads on the elastomer in the area of the crimping are optimised, which extends the service life of the hose line.',
        image: InterlockFitting
    }

    const Data3 = {
        title: 'Special fittings made-to-measure',
        description: 'In addition to standard crimp fittings, we design and manufacture special fittings for specific fields of use in all diameters and even large series production to the customer’s individual specifications. Our experts provide comprehensive advice taking into account all the aspects of the whole system to arrive at the optimum solution. Then we manufacture the fittings in our production centre at Dresden-Weixdorf to the highest precision.',
        image: SpecialFitting
    }

    const Data4 = {
        title: 'Fittings for extreme conditions of use',
        description: 'Extreme applications and the highest pressures require special solutions. HANSA-FLEX crimp fittings for elastomeric and thermoplastic hoses cover the extremely high pressure range and offer a reliable and safe solution for applications involving high pressures in industrial and mobile hydraulics, such as lifting platforms, agricultural and construction machinery.',
        listItems: ["Crimp fittings for dynamic loads and high pressures", "Suitable for elastomeric and thermoplastic hoses", "Safe and durable systems with components designed to work perfectly together"],
        image: FittingsForExtreme
    }

    const Data5 = {
        title: 'Fittings for low-pressure push-on hoses',
        description: 'With push-on fittings, the hose is simply pushed onto the push-on nipple, which fixes it in place. There is no need for time-consuming preparation of the hose because, unlike high-pressure hoses, they can be cut to length using simple cutting tools instead of cutting machines.',
        listItems: ["Push-on fittings in brass", "Simple assembly without special tools", "Ideal for low-pressure applications"],
        image: FittingForLowPressure
    }

    const Data6 = {
        title: 'Easy to install screw fittings',
        description: 'Screw fittings are recognised for their ease of assembly. The ferrule is screwed onto the hose and then the nipple screwed into the connection piece to create the required connection. These screw fittings are normally assembled without special tools and can be reused.',
        listItems: ["Ease of manufacture of hose lines", "No special tools such as hose presses are required", "The fitting can be dismantled and reassembled"],
        image: EasyToInstall
    }

    const Data7 = {
        listItems: ["Straight hose fittings", "Elbow fittings with elbows (45°, 90° and 180°)", "All commercially available nominal diameters and individually manufactured solutions", "Many types of connections (metric, inch, BSP, UNF, NPT, JIS, SAE)", "High-strength fittings up to 500 bar tested to 2,000,000 impulses"],
        image: TheWide
    }

    const Data8 = {
        listItems: [
            "Fulfillment of all norms and international standards",
            "Excellent compatibility with other components",
            "Designed for high pressures, temperatures and loads",
            "Versatility through a rich variety of product solutions",
            "Excellent availability thanks to our own production facilities and dealer network",
            "Up to 1,500 hours of corrosion resistance for non-deformed items"
        ],
    }


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"HOSE FITTINGS FOR HYDRAULICS"}
                    paragraph={"The fittings designed and manufactured by HANSA-FLEX are perfect for use with the appropriate hose type. They guarantee a safe and leak-free connection. The range includes all commonly called for nominal diameters and connection types and complies with international standards (BS, SAE, DIN and NF). The fittings are zinc-nickel coated and meet the highest requirements for durability and corrosion resistance."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PRESS FITTINGS FROM HANSA-FLEX"}
                    subtitle={"Press fitting systems and solutions for self-assembly"}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"HOSE FITTINGS FROM THE SYSTEM PROVIDER HANSA-FLEX"}
                    subtitle={"HANSA-FLEX considers product quality to be extremely important because the quality of the fitting is crucial to the performance of the hose line."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"HOSE FITTINGS FOR ALL APPLICATIONS"}
                />
                <ImageText
                    data={Data8} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"HOSE FITTINGS FOR ALL APPLICATIONS"}
                />
                <ImageText
                    data={Data4} />
                <ImageText
                    data={Data5}
                    left={false} />
                <ImageText
                    data={Data6} />
            </div>

            <div>
                <SectionHeader
                    title={"THE WIDE HANSA-FLEX FITTINGS RANGE:"}
                    subtitle={"As a one-stop partner, we offer a diverse range of crimp and screw fittings in zinc-nickel coated steel, stainless steel and plug-in fittings in brass."}
                />
                <ImageText
                    data={Data7} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={PreAssembled}
                                title="Preassembled hose lines"
                                desc={"In addition to supplying fittings, our experienced experts can preassemble the specific hose line you require"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={HydraulicHoses}
                                title="Hydraulic hoses for every field of use"
                                desc={"Our wide range of braided, spiral, textile and thermoplastic hoses covers all applications in low, medium and high-pressure hydraulics."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={RightConnections}
                                title="The right connection with hydraulic adapters"
                                desc={"HANSA-FLEX offers an extensive portfolio of hydraulic adapters, such as threaded or transition adapters for all common connection types and standards."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default HoseFittings