import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgHfPartner from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/HfPartner.jpg'
import imgHoseLines from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/hoseLines.jpg'
import imgHFcan from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/HFcan.jpg'
import imgStrict from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/strictTesting.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import imgAdviceAndDelivery from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/AdviceAndDelivery.jpg'
import HFTabs from '../../../Components/HfTabs/HfTabs';
import imgFood from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Food.jpg'
import imgChemicals from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Chemicals.jpg'
import imgMedia from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Media.jpg'
import imgWater from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Water.jpg'
import imgOil from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/Oil.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgPtfeHose from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/PtfeHose.jpg'
import imgMetalHose from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/MetalHose.jpg'
import imgOperationOfHoseLines from '../../../assets/Products/HosesAndHoseLines/Industrail Hoses/OperationOfHoseLines.jpg'




function IndustrialHoses() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const AnExtensive1 = {
        image: imgHoseLines,
        title: 'Hose lines readily available',
        description: 'We can process enquiries for industrial hoses to meet your needs quickly and precisely from our modern central warehouse. Standard products are generally delivered to you within a maximum of two days of receipt of order. We can supply the complete range of industrial hoses to cover any application from construction to food processing, along with all the accessories including connection systems.',
        listItems: ["Our warehouse always stocks more than 1,400 different articles", "The full spectrum of hoses and accessories can be supplied quickly", "Modern warehouse logistics ensure smooth processing"]
    }

    const AnExtensive2 = {
        image: imgHFcan,
        title: 'HANSA-FLEX can always find a solution',
        description: 'As well as standard hoses for transporting chemicals, foodstuff, air, water and abrasive media, HANSA-FLEX also have suction and positive pressure hoses in all variants. Articles not in our usual range can be included in our list of supplied products and manufactured in our specialist hose centre to the customer’s specifications.',
        listItems: ["Hose lines preassembled to the customer’s requirements", "We stock up in advance if notified of needed articles", "Fully stocked containers delivered for large construction sites"]
    }

    const AnExtensive3 = {
        image: imgStrict,  
        title: 'Strict testing and high quality criteria',
        description: 'All hose lines to which T002 (German chemical industry data sheet “Hose lines – safe use”) applies are subject to intensive testing at HANSA-FLEX. These tests include electrical conductivity, pressure testing up to five times the working pressure and the unique identification of hose lines.',
        listItems: ["Manufactured in accordance with the Pressure Equipment Directive, DGUV and T002", "HANSA-FLEX is certified in accordance with DIN EN ISO 9001", "All components from the same supplier for the highest system safety"]
    }

    const AnExtensive4 = {
        image: imgAdviceAndDelivery,
        description: 'In selecting the most suitable hose line for your industrial application, the experts at HANSA-FLEX always consider the wider aspects of your whole system as well as the medium, pressure and temperature.',
        listItems: ["The HANSA-FLEX industrial hose centre stocks over 1,400 articles", "Full range of accessories such as nipples and fittings", "Highly capable specialist hose workshop", "Strict quality standards in accordance with DGUV and T002", "Standard components supplied particularly quickly"]
    }

    //HfTab data
    const imageTextData = [
        {
            title: "Food and drinking water hoses for the highest clea",
            description: "Hose lines used in the food industry need to be extremely abrasion resistant and must provide the highest cleanliness standards. Food hoses from HANSA-FLEX meet these requirements and are free of odours, tastes and toxins, in order to be suitable in accordance with FDA, BFR and the Japanese MHLW requirements for the transport and processing of liquid, solid and fat-containing foodstuffs.",
            buttonName: "MORE INFORMATION",
            buttonLink: "https://shop.hansa-flex.cl/en_GB/",
            image: imgFood
        },
        {
            title: "Inert chemical hoses for extremely high loads",
            description: "Acids, alkalis and salt solutions are highly reactive and corrode hoses. With their multi-layer construction and durable inner layers, HANSA-FLEX chemical hoses are very resistant to chemicals and solvents, making them ideal for use in the chemical and pharmaceutical industries.",
            listItems: ["Transport of acids, alkalis, paints, varnishes and alcohols", "Chemical delivery hoses and paint spraying hoses", "Vacuum-resistant variants also available"],
            buttonName: "CHEMICAL HOSES",
            buttonLink: "https://shop.hansa-flex.cl/en_GB/hoses-industrial/tubos-flexibles-industriales/tubos-flexibles-para-productos-qu%C3%ADmicos/c/webcat_HF_SCH_1060_3760",
            image: imgChemicals
        },
        {
            title: "Robust hoses for abrasive media",
            description: "Granular media such as concrete and sand are particularly wearing on hose lines. Their high abrasion resistance and excellent flexibility mean HANSA-FLEX industrial hoses excel in the transport of abrasive and granular media.",
            listItems: ["Suction, delivery and sandblasting", "Including use with heavy bulk materials", "Weather-resistant construction for outdoor use"],
            buttonName: "ABRASION-RESISTANT HOSES",
            buttonLink: "https://shop.hansa-flex.cl/en_GB/hoses-industrial/industrial-hoses/hoses-for-abrasive-media/c/webcat_HF_SCH_1060_3830",
            image: imgMedia
        },
        {
            title: "Versatile water hoses",
            description: "Water plays a key role in numerous industrial applications, for example for cooling or cleaning. The HANSA-FLEX product portfolio contains a large selection of water hoses for a wide range of applications.",
            listItems: ["Hoses for sewer cleaning, firefighting and construction.", "Industrial, radiator and cleaning equipment hoses", "Soft PVC hoses"],
            buttonName: "ALL WATER HOSES",
            buttonLink: "https://shop.hansa-flex.cl/en_GB/hoses-industrial/industrial-hoses/water-hoses/c/webcat_HF_SCH_1060_3840",
            image: imgWater
        },
        {
            title: "Hoses resistant to oil and fuel",
            description: "Environmental protection and safety at work play a key role in the transport of petrol, diesel or kerosene as well as engine, silicone and unrefined oils. HANSA-FLEX special hoses are designed to provide the highest performance and maximum safety, and are available in a wide range of elastomeric and plastic materials.",
            listItems: ["Fuel station pump and tanker hoses with or without helix", "Suitable for use in potentially explosive atmospheres (ATEX)"],
            buttonName: "ALL FUEL HOSES",
            buttonLink: "https://shop.hansa-flex.cl/en_GB/hoses-industrial/industrial-hoses/oil-and-fuel-hoses/c/webcat_HF_SCH_1060_3810",
            image: imgOil
        },
    ];

    const tabTitles = [
        { title: "Food", eventKey: "Food" },
        { title: "Chemicals", eventKey: "Chemicals" },
        { title: "Abrasive media", eventKey: "Abrasive media" },
        { title: "Water", eventKey: "Water" },
        { title: "Oils and fuels", eventKey: "Oils and fuels" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHfPartner}
                    title={"HANSA-FLEX IS YOUR SYSTEM PARTNER FOR INDUSTRIAL HOSES"}
                    paragraph={"Industrial hoses find application where the medium is not used for transmitting power. Industrial hoses transport a wide range of media from water and air through chemical substances right up to fuels and gases in almost every field of industry. In its specialist hose workshop, HANSA-FLEX fulfils the customer’s requirements to the highest standards of quality and shortest delivery schedules. You can find a wide choice of industrial hoses in the HANSA-FLEX online shop."}
                />
                {/*TODO button*/}
            </div>

            <div>
                <SectionHeader
                    title={"AN EXTENSIVE RANGE AND HIGH QUALITY FROM A SINGLE SUPPLIER"}
                />
                <div>
                    <ImageText
                        data={AnExtensive1} />
                    <ImageText
                        data={AnExtensive2}
                        left={false} />
                    <ImageText
                        data={AnExtensive3} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"HANSA-FLEX STANDS FOR DEPENDABLE ADVICE AND RAPID DELIVERY"}
                    subtitle={"At its industrial hose centre, HANSA-FLEX has all the resources to provide expert advice and supply products quickly."}
                />
                <ImageText data={AnExtensive4} />
            </div>

            <div>
                <SectionHeader
                    title={"SUCTION AND DELIVERY HOSES FOR INDUSTRIAL APPLICATIONS"}
                    subtitle={"HANSA-FLEX can supply high-quality water, air and gas hoses, as well as hoses for oils, fuels, solids, hot media, chemicals, solvents and foodstuff."}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            {/* TODO: TextPoster */}

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgPtfeHose} learnMoreLink={"/Ptfe-hoses"}
                                title="PTFE hoses with high chemical resistance"
                                desc={"Because of their high chemical resistance against most media, PTFE hoses from HANSA-FLEX are the first choice for all challenging applications."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMetalHose} learnMoreLink={"/metal-hoses"}
                                title="Metal hoses for all conditions of use"
                                desc={"High temperature tolerance and resistance to aggressive media lie behind the special performance capabilities of metal hoses."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgOperationOfHoseLines} learnMoreLink={"/"}
                                title="Safe and proper operation of hose lines"
                                desc={"Industrial hoses are subject to numerous safety-related requirements. HANSA-FLEX gives the essential details of these in the technical information for the products."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    );
}

export default IndustrialHoses;
