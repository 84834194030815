import React, { useEffect } from 'react';
import pdfForcedLabourAct from '../assets/FightingAgainstForcedLabourAndChildLabourInSupplyChainAct.pdf';
import FullTextButton from '../Components/FullTextButton/FullTextButton';


function DeligenceAct() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <FullTextButton title={"German Supply Chain Due Diligence Act"} buttonLink={"https://www.hansa-flex.de/en/complaints-procedure-8-lksg/"} paragraph={"It contains the regulations on how HANSA‑FLEX AG intends to contribute to respect for human rights and the environment in its own company and along the supply chain."} buttonText={"Read full German Supply Chain Due Diligence Act"} />
        </div>
    );
}

export default DeligenceAct;