import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgProduct_Pipebends_Centerstage from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_centerstage.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgProductPipebendimagetext1 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext1.jpg'
import imgProductPipebendimagetext2 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext2.jpg'
import imgProductPipebendimagetext3 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext3.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import imgProductPipebendimagetext4 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext4.jpg'
import imgProductPipebendimagetext5 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagetext5.jpg'
import imgProductPipebendimagetext6 from '../../../assets/Products/Pipelines/PipeBends/SpecialPipes.jpg'
import imgMark from '../../../assets/mark.png'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import { Container, Row, Col } from 'react-bootstrap';
import imgProductPipebendimagecard1 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagecard1.jpg'
import imgProductPipebendimagecard2 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagecard2.jpg'
import imgProductPipebendimagecard3 from '../../../assets/Products/Pipelines/PipeBends/Products_Pipebend_imagecard3.jpg'


const Pipebends = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const ProductPipebendimagetext1 = {
    image: imgProductPipebendimagetext1,
    title: 'The pipe bending centre and branches work hand-in-hand',
    description: ['The experts in the HANSA-FLEX pipe bending centre have the necessary skills and knowledge of pipe bending and fluid technology to master the most complex challenges. They work closely with the specialists from the HANSA-FLEX branches. You can expect the best possible advice and an optimum path from conception to manufacture. On request, HANSA-FLEX is happy to manufacture ready-to-install pipe sets and deliver them Just-In-Time directly to the customer’s installation site, so that the entire process after pipe bending runs smoothly.'],
  }

  const ProductPipebendimagetext2 = {
    image: imgProductPipebendimagetext2,
    title: 'Checking the shape of pipe bends',
    description: ['The bending experts at HANSA-FLEX determine all the data required for the bent pipe from the computer simulation. If the simulation shows that the degree of ovalisation in the bend area exceeds the 10 % limit applied in hydraulics, then an internal mandrel is used during bending to prevent the pipe cross section from narrowing.'],
  }


  const ProductPipebendimagetext3 = {
    image: imgProductPipebendimagetext3,
    title: 'Well-equipped production floor',
    description: ['To meet the diverse requirements for pipe geometry needs many more than one type of machine. The extensive range of machines in the pipe bending centre allows HANSA-FLEX to perform a wide variety of bending tasks and produce the highest quality results.'],
  }


  const ProductPipebendimagetext4 = {
    image: imgProductPipebendimagetext4,
    title: 'Bending parameters defined using computer simulations',
    description: ['All enquiries for bent pipes are first subjected to a HANSA-FLEX feasibility check. This simulation is done with visualisation software. The software considers all parameters such as bend length, stretching and springback. From these values, it calculates the precise sawn length of pipe that will result in a ready-to-install bent pipe after bending. A suitable CNC bending machine makes the bent pipe using the data from the simulation. The manufactured prototype is then checked using a measuring arm and compared to the master data. If all the dimensions agree, the feasibility check is passed under the HANSA-FLEX technician gives the green light for production.'],
  }

  const ProductPipebendimagetext5 = {
    image: imgProductPipebendimagetext5,
    title: 'Special pipes and fittings from Weixdorf',
    description: ['Individually manufactured pipelines and fittings are often required in fluid technology. HANSA-FLEX set up a special manufacturing centre for special pipes and fittings at Dresden-Weixdorf specifically to satisfy this demand. All components, whether one-off or series production, are manufactured here with computer-controlled precision. Left and right pipe bending machines can master even complex pipe geometries. Machines equipped with a turret can make bend pipes to different radii in one working operation. This allows HANSA-FLEX greater flexibility when making customised connections and shortens delivery times.'],
  }

  const ProductPipebendimagetext6 = {
    image: imgProductPipebendimagetext6,
    description: ['Drawing on their many years of manufacturing experience in the field of bent pipelines and special fittings, our experts can realise the most complex special designs precisely to meet your requirements.'],
    listItems: ["Precision pipes in combination with hydraulic hoses", "All commercially available connection variants can be supplied", "Multiply bent pipes, adapters, collectors or manifolds", "Preassembled component groups for rapid installation"]
  }

  const featuresData = [
    {
      title: "Very high accuracy",
      description: "Manufacturing accuracies of up to 0.3 mm, depending on the total length of the product.",
      image: imgMark
    },
    {
      title: "Manufacturing exactly to size",
      description: "Pipes up to 6,000 mm long and 4.00 to 101.60 mm diameter.",
      image: imgMark
    },
    {
      title: "Manufacturing flexibility",
      description: "A large selection of tools to create the perfect radius for your requirements.",
      image: imgMark
    },
    {
      title: "All available materials",
      description: "HANSA-FLEX bends precision steel pipes from stainless steel or copper",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];

  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imgProduct_Pipebends_Centerstage}
          title={"BENT PIPES FOR FLUID TECHNOLOGY FROM HANSA-FLEX"}
          paragraph={"Bent pipes are used not only in tight installation situations but also for complex pipework systems. HANSA-FLEX has set up a pipe bending centre which all branches of the company can access. The HANSA-FLEX pipe bending centre manufactures bent precision pipes from a variety of materials for all our branches. The pipes are available with all common connection types and in the proven HANSA-FLEX quality."}
        />
      </div>
      {/* <SectionHeader
        title={"BENT PIPES MANUFACTURED WITH ABSOLUTE PRECISION"}
        subtitle={"Two short videos give interesting insights into the manufacturing processes in the HANSA-FLEX pipe bending centre in Schönebeck and the special fitting manufacturing facility in Weixdorf."}
      /> */}
      {/* TODO: Video_Leftrightcontainer */}

      <div>
        <SectionHeader
          title={"DEPENDABLE COMPETENCE AND QUALITY"}
          subtitle={"HANSA-FLEX makes use of synergies between the specialist departments to meet the highest customer expectations in the fabrication of pipes and special fittings."}
        />
        <ImageText data={ProductPipebendimagetext1} left={false} />

        <ImageText data={ProductPipebendimagetext2} />

        <ImageText data={ProductPipebendimagetext3} left={false} />
      </div>


      <div>
        <SectionHeader
          title={"HANSA-FLEX BENT PIPES FIT YOUR REQUIREMENTS EXACTLY"}
          subtitle={"HANSA-FLEX always strives to fulfil every individual customer requirement to the best possible standard. The production floor for special pipes and fittings is equipped to offer customers a wide choice of possible ways of implementing any project."}
        />
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div>
        <SectionHeader
          title={"FEASIBILITY CHECK AND CUSTOM-MADE COMPONENTS"}
          subtitle={"HANSA-FLEX manufactures bent pipes, special pipes and special fittings in a variety of practical forms quickly, precisely and in the proven quality suitable for OEM suppliers or as replacement parts."}
        />
        <ImageText data={ProductPipebendimagetext4} left={false} />
        <ImageText data={ProductPipebendimagetext5} />
      </div>

      <div>
        <SectionHeader
          title={"SPECIAL PIPES AND FITTINGS FROM HANSA-FLEX"}
          subtitle={"You benefit from a number of services when HANSA-FLEX manufactures special solutions."}
        />
        <ImageText data={ProductPipebendimagetext6} left={false} />
        \      </div>

      <div>
        <SectionHeader
          title={"ADDITIONAL SERVICES AND PRODUCTS"}
        />
        <Container className="custom-container">
          <Row>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
              <ImageCard image={imgProductPipebendimagecard1} learnMoreLink="/hydraulic-hoses" title="Bent pipes precisely manufactured to the customer’s requirements" desc="HANSA-FLEX has decades of experience in bending precision pipes made from steel, stainless steel, copper and other materials. Lengths of up to 6,000 mm and diameters..." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
              <ImageCard image={imgProductPipebendimagecard2} learnMoreLink="/metal-hoses" title="Customised pipe-end processing and special fittings" desc="HANSA-FLEX offers customised connection options for every pipe connection. All types of forming processes, such as swaging or rolling, in addition to cutting ring, threaded..." />
            </Col>
            <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
              <ImageCard image={imgProductPipebendimagecard3} learnMoreLink="/Industrial-hoses" title="Protect systems with HANSA-FLEX expansion joints" desc="HANSA-FLEX expansion joints in silicone, PTFE, NBR or stainless steel protect machines and equipment against damage from shocks, expansion and vibration..." />
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  )
}

export default Pipebends