import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { FaPhoneVolume } from "react-icons/fa6";
import { MdLocationOn, MdOutlineEmail } from "react-icons/md";
import { BiLogoFacebookCircle } from "react-icons/bi";
import Imgxing from '../../assets/xingImg.png'
import Imglinkidin from '../../assets/Linkdin.png'
import Imgyoutube from '../../assets/youtube.png'
import Imgkaggle from '../../assets/kaggleImg.png'
import Imgfacebook from '../../assets/Facebook.png'
import { Link } from 'react-router-dom';
import './HfFooter.css'
import imgCaGov from '../../assets/ca-gov.png';
import accessibility from '../../assets/policy/Accessibility-Policy-HFC.pdf';
import { useTranslation } from 'react-i18next';


const HfFooter = () => {
  const { t } = useTranslation();
  return (
    <footer className='hfFooter-wrapper' id='contact-us-section'>
      <Container className='hfFooter-container'>
        <Row className="justify-content-center " style={{ marginLeft: 'auto' }}>
          <Col sm={12} lg={4} >
            <div className='footer-column-div'>
              <b>{t('How to reach us')}</b>
              <ul>
                <li><a href="tel:+19057602224" className="footer-link"><FaPhoneVolume /> (+1) 905 760 2224</a></li>
                <li><a href="mailto:cac@hansa-flex.com" className="footer-link"><MdOutlineEmail /> cac@hansa-flex.com</a></li>
                <li><a href="https://www.google.com/maps/place/HANSA-FLEX+Hydraulics+Canada+Inc./@43.8312837,-79.4875326,14.73z/data=!4m14!1m7!3m6!1s0x882b2ebd43362dd3:0xe2303558a2b73369!2sHANSA-FLEX+Hydraulics+Canada+Inc.!8m2!3d43.8198127!4d-79.4821777!16s%2Fg%2F1tgcb43h!3m5!1s0x882b2ebd43362dd3:0xe2303558a2b73369!8m2!3d43.8198127!4d-79.4821777!16s%2Fg%2F1tgcb43h?entry=ttu&g_ep=EgoyMDI0MDkwMi4xIKXMDSoASAFQAw%3D%3D" className="footer-link"><MdLocationOn /> 3-295 Connie Crescent, Concord, ON L4K 5R2, Canada</a></li>
              </ul>
              <br />

              <img src={imgCaGov} alt="Location Image" style={{ width: '200px', height: '60px', boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }} />
              <br /><br /><br />
            </div>

          </Col>

          <Col sm={12} lg={4}>

            <div className='footer-column-div'>
              <b>{t("About HANSA-FLEX")}</b>
              <ul>
                <li><a href="https://www.hansa-flex.de/en/magazine/" className="footer-link">Hydraulic Press</a></li>
                <li><Link to="/certificates" className="footer-link">ISO 9001:2015 Certificate</Link></li>
                <li><a href={accessibility} className="footer-link">Accessibility</a></li>
                <li><Link to="/compliance" className="footer-link">Compliance</Link></li>
                {/* <li><Link to="/DiligenceAct" className="footer-link">Diligence Act</Link></li> */}



                {/* Uncomment the line below if needed */}
                {/* <li><Link to="/careers" className="footer-link">Careers</Link></li> */}
              </ul>
              <br />
            </div>
          </Col>


          {/* <Col xs={4} lg={3}>
          <div className='footer-column-div'>
          <h>Service</h>
          <ul>
                <li>Mobile rapid hydraulic service</li>
                <li>Fluid management</li>
                <li>Assembly and installation</li>
                <li>Hydarulic cylinder repair</li>
                <li>Preventive maintenance</li>
            </ul>
          </div>
          </Col> */}

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              <b>{t('Popular categories')}</b>
              <ul>
                <li><Link to="/hoses-and-hose-lines" className="footer-link">{t("Products")}</Link></li>
                <a href="https://my.hansa-flex.ca/en_GB/login" target="_blank" rel="noopener noreferrer" className="footer-link">{t("X-CODE Manager")}</a>


              </ul>
              <br />
            </div>
          </Col>

        </Row>

        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <b>{t('Follow us')}</b>
          </Col>
        </Row>


        <Row className="custom-3row ">

          {/*<Col><div className="circle-col"><img src={Imgkaggle} alt="" /></div></Col>*/}
          {/*<Col><div className="circle-col"><img src={Imgxing} alt="" /></div></Col>*/}
          <Col><div className="circle-col"><a href="https://www.kununu.com/de/hansa-flex" target="_blank" rel="noopener noreferrer"><div><img src={Imgkaggle} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.xing.com/pages/hansa-flexag" target="_blank" rel="noopener noreferrer"><div><img src={Imgxing} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.linkedin.com/company/hansa-flex-ag" target="_blank" rel="noopener noreferrer"><div><img src={Imglinkidin} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.youtube.com/@HANSAFLEXAG" target="_blank" rel="noopener noreferrer"><div><img src={Imgyoutube} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://de-de.facebook.com/hansaflexag" target="_blank" rel="noopener noreferrer"><div><img src={Imgfacebook} alt="" /></div></a></div></Col>

        </Row>

        <Row className="line-row">
          <Col className="line-col"></Col>
        </Row>

        <div className='HfFooter-link-div'>
          <a className="hf-footer-link" href="">{t("© Copyright, All rights reserved")}</a><span>  |  </span>
          <Link to="/hansa-flex-india-privacy-policy" className="footer-link">{t("Privacy Policy")}</Link>


          {/*<a className="hf-footer-link" href="">GT&C</a><span>  |  </span>
          <a className="hf-footer-link" href="">Purchasing and logistics</a><span>  |  </span>
        <a className="hf-footer-link" href="">Data protection</a>*/}

        </div>



      </Container>
    </footer >

  );
};

export default HfFooter;
