import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';

import imgStage from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/stage.jpg'
import imgSuitable from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/suitable.jpg'
import imgExtensive from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/extensive.jpg'
import imgExtensiveRangeOfServices from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/ExtensiveRangeOfServices.jpg'
import imgMetalHoses from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/MetalHoses.jpg'
import imgIndustrialHoses from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/IndustrialHoses.jpg'
import imgOneOff from '../../../assets/Products/HosesAndHoseLines/PtfeHoses/OneOff.jpg'


const PtfeHoses = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const PtfeHose1 = {
        image: imgSuitable,
        title: 'Suitable for almost any medium',
        description: 'PTFE hoses from HANSA-FLEX are suitable for liquid, solid and gaseous media in a wide range of temperatures from -60 °C to +260 °C. They are particularly recommended for use in the chemical, automotive, electrical and medical technology industries. PTFE hoses are also used in ship construction and in the food industry.',
        listItems: ["The basic material has FDA approval", "Resistant to almost all chemicals", "Very good mechanical properties for long-term durability"]
    }

    const PtfeHose2 = {
        image: imgExtensive,
        title: 'Extensive product range',
        description: 'PTFE hoses from HANSA-FLEX are available in nominal diameters of DN 4 mm to DN 100 mm. These hoses are available as metal-sheathed, double-walled or elbow variants. Antistatic PTFE hoses are suitable for use in EX zones. We can accommodate specific requirements for connections from our range of special fittings.',
        listItems: ["Nominal diameters from DN 4 mm to DN 100 mm", "Crimped or flared connection components", "Special fittings from HANSA-FLEX"]
    }

    const PtfeHose3 = {
        image: imgExtensiveRangeOfServices,
        title: 'Extensive range of services from HANSA-FLEX',
        description: 'Our trained staff advise you on the selection and design of PTFE hoses. Customer-specific requirements are fulfilled as one-off, small batch or series manufactured items.',
        listItems: ["Comprehensive advice in all project phases, by telephone or on site", "Use of the latest test methods in quality assurance", "Highly flexible solutions for hose lines and fittings"]
    }

    const certifiedQuality = [
        {
            title: "Suitable for food (FDA)",
            description: "Odour and taste-neutral, easy to clean and bacteriologically safe.",
            image: imgMark
        },
        {
            title: "Approved for shipbuilding",
            description: "HANSA-FLEX meets the requirements of DNV, Lloyd’s Register and the American Bureau of Shipping.",
            image: imgMark
        },
        {
            title: "Outstandingly durable",
            description: "Long service life due to UV and vibration resistance, incombustible.",
            image: imgMark
        },
        {
            title: "Outstanding anti-adhesion properties",
            description: "Outstanding anti-adhesion properties allow problem free transport of sticky substances.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"PTFE HOSES FOR CHEMICALLY CHALLENGING APPLICATIONS"}
                    paragraph={"PTFE hoses from HANSA-FLEX excel through their excellent chemical resistance to most media, high flexibility and a long service life. In the food and pharmaceutical industries, their taste and odour neutrality as well as bacteriological safety make them ideal for transporting potentially problematic products. In shipbuilding or the aerospace industry, PTFE hoses safely transport fuel or coolant water."}
                //TODO button
                />
            </div>

            <div>
                <SectionHeader
                    title={"PTFE HOSES FROM HANSA-FLEX FOR A WIDE RANGE OF APPLICATIONS"}
                />
                <div>
                    <ImageText
                        data={PtfeHose1} />

                    <ImageText
                        data={PtfeHose2}
                        left={false} />

                    <ImageText
                        data={PtfeHose3} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"PTFE HOSE LINES WITH TOP-CLASS PRODUCT CHARACTERISTICS"}
                    subtitle={"Their high temperature and chemical resistance ensure these high-grade PTFE products from HANSA-FLEX can perform as the true all-rounders among hose lines in numerous fields of application."}
                />
                <FeatureCardCarousel
                    featuresData={certifiedQuality} />
            </div>

            {/* TODO: Video */}
            {/* TODO: Carousal */}
            {/* <div>
                <SectionHeader
                    title={"TOP-CLASS INDUSTRIAL HOSES FROM THE FLUID SPECIALISTS"}
                    subtitle={"Our extensive product portfolio contains industrial hoses for all fields of use, from the food industry to fuel and oil hoses. We have the right hose for you in the right material, whether it is Viton, EPDM, PUR or NBR."}
                />
            </div> */}

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMetalHoses}
                                title="Metal hoses with excellent resistance properties"
                                desc={"High temperature tolerance and resistance to aggressive media lie behind the special performance capabilities of metal hoses."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgIndustrialHoses}
                                title="Industrial hoses in the highest quality"
                                desc={"HANSA-FLEX can produce industrial hoses to individual customer requirements quickly and competently in its own specialist hose workshop."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgOneOff}
                                title="One-off special solutions"
                                desc={"Solutions have to be at least as diverse and wide-ranging as the applications. HANSA-FLEX solutions can be anything from one-off, single products to series-produced batches..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default PtfeHoses