import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import Promoter from '../../../Components/Promoter/Promoter';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import HFTabs from '../../../Components/HfTabs/HfTabs';


import imgHose from '../../../assets/hose.jpg'
import imgHydraulicHoses from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/hydraulic-hoses.jpg'
import imgMetalHose from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/metalHose.jpg'
import imgBluePipe from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/bluePipe.jpg'
import imgjetSpray from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/jetSpray.jpg'
import imgIndustrailHoses from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/industrialHoses.jpg'
import imgperforemdHoses from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/performedHoses.jpg'
import imgaccessoresHoseLine from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/accessoriesHoseLines.jpg'
import imgTabletMobile from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'
import imgHoseforcompressed from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/hoseForCompressed.jpg'
import imgThePremiumHose from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/thePremiumHose.jpg'
import imgSwitchHoseLine from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/switchHoseLines.jpg'
import imghosekit from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/hoseKits.jpg'
import imgModernSeries from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/modernSeries.jpg'
import imgCustomisedHose from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/customisedHose.jpg'
import imgFittings from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/Fittings.jpg'
import imgCouplings from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/Couplings.jpg'
import imgAdapters from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/Adapters.jpg'


//TODO: TextPoster

function HosesAndHoseLines() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const dataPipeFittings = {
        image: imgHoseforcompressed,
        title: 'Hoses for compressed air technology',
        description: 'Our online shop has a large selection of pneumatic hoses in a wide range of diameters, materials and lengths.',
        listItems: ["Compressor and brake hoses", "PA, PE, PUR, PVDF and PVC hoses", "Pipelines, pipe flanges, fastenings and connections"]
    }

    // Product Development data

    const dataProductDevelopment1 = {
        image: imgThePremiumHose,
        title: 'The premium hose line for high pressures',
        description: 'The HD 700 LL premium hose line from HANSA-FLEX was specially developed for use under demanding conditions. It combines maximum performance with a long service life.',
        listItems: ["Excellent pressure load capacity, even after 2 million impulse cycles", "Available in sizes from DN 31 to DN 51", "DNV approval for maritime use"],
        buttonName: "MORE HYDRAULIC HOSES",
        buttonLink: "https://shop.hansa-flex.cl/es_ES/hoses/hydraulic-hoses/spiral-hoses/hd-700-ll/p/MAS_HD_700_LL"
    }

    const dataProductDevelopment2 = {
        image: imgSwitchHoseLine,
        title: 'Switch hose lines in seconds using the SSKV coupling',
        description: 'Push-in shell clamp connectors for hydraulic hoses combine the advantages of couplings and conventional fittings to reduce the amount of labour and time involved when switching hose lines.',
        listItems: ["Additionally fixed in place by a hand-operated knurled screw.", "Secured against unintentional release under pressure", "No-tool assembly makes it ideal for tight installation spaces"],
        buttonName: "MORE SSNK COUPLINGS",
        buttonLink: "https://shop.hansa-flex.cl/es_ES/hose-fittings/push-in-clamping-shell-connections-sskv/c/webcat_HF_SAR_1320"
    }

    // Features card carousel data
    const featuresData = [
        {
            title: "Preassembled hose lines",
            description: "We preassemble all commercially available hose types into ready-to-install customised hose lines.",
            image: imgMark
        },
        {
            title: "Hose line testing",
            description: "As officially authorised persons for the inspection of hydraulic equipment, our experts can inspect and test hydraulic and industrial hose lines.",
            image: imgMark
        },
        {
            title: "Smart hose management",
            description: "Have all your hose information, such as inspection intervals, ready to hand with HANSA-FLEX X-CODE Manager.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    // HfTab data
    const imageTextData = [
        {
            title: "Hose fittings for all applications",
            description: "Fittings designed and manufactured by HANSA-FLEX are perfect for use with their intended hose types. They guarantee a safe and leak-free connection.",
            listItems: ["Crimp, interlock and special fittings", "All commercially available nominal sizes and connection types", "Corrosion-resistant zinc-nickel surface coating"],
            buttonName: "LEARN MORE",
            buttonLink: "/hose-fitting",
            image: imgFittings
        },
        {
            title: "Safe and dependable hydraulic couplings",
            description: "Couplings are the best option in situations where attachments need to be quickly exchanged, machines safely connected or highly loaded hose lines on mobile and fixed hydraulic systems changed over.",
            listItems: ["A suitable quick coupling for every application", "Available in various materials such as steel or stainless steel", "Numerous sizes and seal types"],
            buttonName: "LEARN MORE",
            buttonLink: "/coupling",
            image: imgCouplings
        },
        {
            title: "Make the right connection with hydraulic adapters",
            description: "Adapters create a safe and leak-free connection between components with differing thread types. HANSA-FLEX offers an extensive range of hydraulic adapters.",
            listItems: ["Threaded and transition adapters", "All commercially available connection types and standards", "Special types such as 45° SAE connections also supplied"],
            buttonName: "LEARN MORE",
            buttonLink: "/adapters",
            image: imgAdapters
        },
    ];

    const tabTitles = [
        { title: "Fittings", eventKey: "Fittings" },
        { title: "Couplings", eventKey: "Couplings" },
        { title: "Adapters", eventKey: "Adapters" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHose}
                    title={"HIGH-QUALITY HOSES FROM HANSA-FLEX"}
                    paragraph={"The HANSA-FLEX range includes hoses for hydraulics and pneumatics as well as for the transport of chemicals, food, air, water and abrasive media. In addition to standard hoses, we also supply suction and positive pressure hoses, suitable accessories and preassembled hose lines manufactured to your individual requirements. As a full-service provider, we also support our customers with installation, maintenance and inspection."}
                />
            </div>
            <SectionHeader
                title={"THE COMPLETE HANSA-FLEX RANGE OF COUPLINGS"}
                subtitle={"HANSA-FLEX is recognised as an extremely capable system provider for high-quality hydraulic hose lines. But our know-how extends much further:"}
            />
            <div>
                <Container className="custom-container">
                    <Row>
                        <Col sm={12} md={6} lg={8} ><ImageCard image={imgHydraulicHoses} learnMoreLink={"/hydraulic-hoses"}
                            title="Hydraulic hoses for low, medium and high-pressure applications"
                            desc={"HANSA-FLEX offers high-quality hydraulic hose lines for low, medium and high pressures, covering all applications in mobile and stationary hydraulics. The portfolio includes hoses with an outer layer of elastomer or thermoplastic and pressure carrier layers of steel wire braid, spirally wound steel wire or textiles for pressure ranges up to 500 bar."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgMetalHose} learnMoreLink={"/metal-hoses"}
                            title="Metal hoses for extreme temperature ranges"
                            desc={"Metal hoses are suitable for the transport of solid, liquid and gaseous materials because they can accept high loads, are strong under vacuum and temperature resistant."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgBluePipe} learnMoreLink={"/Ptfe-hoses"}
                            title="PTFE hoses for chemically challenging applications"
                            desc={"PTFE hoses from HANSA-FLEX excel through their chemical resistance to most media, their high flexibility and long service life."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgjetSpray} learnMoreLink={"/ext-high-pfm-hoses"}
                            title="Thermoplastic extremely high-pressure hoses for up to 3,200 bar"
                            desc={"Extremely high pressures demand first-class material and quality of workmanship. HANSA-FLEX preassembles high-quality, extremely high-pressure hose lines using..."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgIndustrailHoses} learnMoreLink={"/Industrial-hoses"}
                            title="Industrial hoses for all areas of use"
                            desc={"Our product range covers industrial hoses for transporting chemicals, food, air, water and abrasive media, as well as suction and positive pressure hoses."} /></Col>

                        <Col sm={12} md={6} lg={4} ><ImageCard image={imgperforemdHoses} learnMoreLink={"/performed-hoses"}
                            title="Preformed hoses for the greatest flexibility in the tightest spaces"
                            desc={"Preformed hoses are highly elastic, robust and can be made to almost any shape. In addition to standard hoses, HANSA-FLEX also provides custom-made solutions"} /></Col>

                        <Col sm={12} md={6} lg={8} ><ImageCard image={imgaccessoresHoseLine} learnMoreLink={"/hose-accessories"}
                            title="Accessories for your hose lines"
                            desc={"In addition to first-class hoses, the HANSA-FLEX range includes accessories suitable for hose line applications in mobile and stationary hydraulic systems. We offer solutions for rubbing, heat and kinking protection, hose bundling and other safety measures such as hose restraints."} /></Col>
                    </Row>
                </Container>
            </div>

            <div>
                <Promoter
                    title={"DISCOVER THE HANSA-FLEX HOSE LINE CONFIGURATOR NOW"}
                    checklistItems={["Compact hoses for confined spaces, e.g. mobile hydraulics", "Thermoplastic hoses for particularly low weight"]}
                    btnLabel1={"CONFIGURE NOW"}
                    btnLabel2={"LEARN MORE"}
                    btnLabel1Link={"https://shop.hansa-flex.cl/es_ES/"}
                    btnLabel2Link={"/hose-line-config"}
                    imageUrl={imgTabletMobile}
                />
            </div>

            <div>
                <ImageText
                    data={dataPipeFittings} />
            </div>

            {/* TODO: TextPoster */}

            <div>
                <SectionHeader
                    title={"SERVICES FOR HOSE LINES"}
                    subtitle={"HANSA-FLEX is a system partner providing not only high-quality hoses and associated products but also supporting customers with numerous services for all tasks involving hoses."}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"PRODUCTS FOR HOSE LINE APPLICATIONS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"PRODUCT DEVELOPMENTS MADE BY HANSA-FLEX"}
                    subtitle={"We know what our customers want and develop the perfect solutions."}
                />

                <ImageText
                    data={dataProductDevelopment1} />

                <ImageText
                    data={dataProductDevelopment2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"FURTHER SERVICES FROM THE FLUID EXPERTS HANSA-FLEX"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imghosekit}
                                title="Hose kits for an efficient installation"
                                desc={"Increase your efficiency and production output with hose line kits. Kit composition to your individual requirements"}
                                learnMoreLink={"/Kitting"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgModernSeries}
                                title="Modern series production for OEM component suppliers"
                                desc={"The HANSA-FLEX central production facility in Bremen manufactures hose lines in large and small batches. The range includes high-pressure, PTFE and industrial hoses in..."}
                                learnMoreLink={"/SeriesProduction"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCustomisedHose}
                                title="Customised hose preassembly anywhere in the world."
                                desc={"The Hydraulic Service Container is a mobile hydraulics workshop for manufacturing hoses directly on site, saving time and minimising costly stoppages"}
                                learnMoreLink={"/Hydraulicworkshopcontainers"} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>

    );
}

export default HosesAndHoseLines;