import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'
import HFTabs from '../../../Components/HfTabs/HfTabs'

import Stage from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/Stage.jpg'
import TwoWay from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/TwoWay.jpg'
import MultiWay from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/MultiWay.jpg'
import LowAndHigh from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/LowAndHigh.jpg'
import Safe from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/Safe.jpg'
import Right from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/Right.jpg'
import LeakProof from '../../../assets/Products/HydraulicConnectionTechnology/BallValves/LeakProof.jpg'

const BallValves = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Data1 = {
        title: 'Two-way straight ball valves',
        description: 'Two-way straight ball valves are used where media under pressure needs to be reliably shut off. By turning the operating shaft, the flow in 2/2-way valves can be closed and opened. A simple and safe way of shutting off the flow in hydraulic systems.',
        listItems: ["2-way switching, L or T functions", "For shutting off the flow"],
        image: TwoWay
    }

    const Data2 = {
        title: 'Multi-way ball valve',
        description: 'Multi-way ball valves have an L, T or X bore. Moving the ball allows the flow to be divided between several outlets or for the flow to various parts of a pipe line system to be shut-off.',
        listItems: ["3- and 4-way switching, L, T or X functions", "For control and direction", "Dividing flow between several outlets"],
        image: MultiWay
    }

    const Data3 = {
        listItems: [
            "2-way straight and multi-way ball valves with L, T or X bores",
            "Suitable for temperatures of -20 °C to +60 °C",
            "Numerous connection options, such as SAE threads and 24° pipe fittings",
            "In block and forged formats with no reduction in flow",
            "Burnished, Cr-VI free zinc plated or galvanised",
            "Pneumatic and electro-mechanical motorised drive on request"
        ],
        image: LowAndHigh
    }

    const featuresData = [
        {
            title: "Flexible in use",
            description: "A wide range of connection options, also usable as end of run fittings.",
            image: imgMark
        },
        {
            title: "Pressure resistant",
            description: "Very good seal for long-term operation at various ranges of pressure.",
            image: imgMark
        },
        {
            title: "User-friendly operation",
            description: "Simple switching without tools, robust ball valve design",
            image: imgMark
        },
        {
            title: "Reliable safety",
            description: "Tested safety and sealing performance, supplied with protective caps.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"HANSA-FLEX BALL VALVES FOR MAXIMUM SEALING PERFORMANCE AND RELIABILITY"}
                    paragraph={"HANSA-FLEX ball valves are used to shut off and divert flow in hydraulic pipe systems at pressures of up to 500 bar and thus avoid need to convert or uninstall components. The valves have a floating ball between seals, which means they are low maintenance and have only low losses thanks to the large bore. HANSA-FLEX ball valves fulfil the requirements of all standards and safety regulations regarding strength and sealing performance."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"OUR PRODUCT PORTFOLIO IN THE FIELD OF BALL VALVES"}
                    subtitle={"Ball valves reliably shut-off and divert media flow"}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"SHUT-OFF AND DIRECT MEDIA UNDER PRESSURE"}
                    subtitle={"Ball valves from HANSA-FLEX perform perfectly in hydraulic pipe systems."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}

            <div>
                <SectionHeader
                    title={"BALL VALVES FOR LOW AND HIGH PRESSURES"}
                    subtitle={"Our portfolio covers ball valves in sizes from DN 4 to DN 40 in various materials such as steel or stainless steel, different designs and variants, including low-pressure versions in accordance with DVGW."}
                />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"MORE HYDRAULIC COMPONENTS FROM HANSA-FLEX"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={Safe}
                                title="Safe and reliable hydraulic couplings"
                                desc={"Couplings from HANSA-FLEX allow you to quickly and easily connect and disconnect hose lines This saves you time in assembly, repair and component replacement"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Right}
                                title="The right connection with hydraulic adapters"
                                desc={"HANSA-FLEX adapters fit perfectly with all commercially available connection types and standards to produce a leak-free connection of different threads."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={LeakProof}
                                title="Leak-proof positive connection with pipe fittings"
                                desc={"HANSA-FLEX standardised pipe fittings create a safe connection even when subjected to high working pressures, extreme pressure peaks and vibrations."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>


        </div>
    )
}

export default BallValves