import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './MainNavbarMenu.css';
import logo from '../../assets/logo.svg';
import { FaMapMarkerAlt, FaPhone, FaLanguage, FaCode, FaShoppingCart } from 'react-icons/fa'; // Importing icons
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';




function MainNavbarMenu() {

  const [isSticky, setIsSticky] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { i18n, t } = useTranslation();
  const [checked, setChecked] = useState(i18n.language === 'fr');

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    const newLang = nextChecked ? 'fr' : 'en';
    i18n.changeLanguage(newLang);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDropdownClose = () => {
    setExpanded(false);

  };


  return (
    <Navbar className="sec-navbar" bg="light" expand="lg" expanded={expanded}>
      <Navbar.Brand className={` nav-brand-main`} as={Link} to="/">
        <img
          src={logo}
          className={`d-inline-block align-top logo-nav-main ${isSticky ? "logo-stuck" : "logo-not-stuck"}`}
          alt="Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav navbar-toggle-hamburger" onClick={() => setExpanded(!expanded)}></Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto margin-l basic-navbar-main-menu">
          <div className="nav-wrapper">
            <NavDropdown title="HANSA-FLEX Canada" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown.Item as={Link} to="/who-we-are">{t("Who we are")}</NavDropdown.Item>

              {/* <NavDropdown.Item as={Link} to="/management">Management</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/journey">{t("Journey")}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/certificates">{t("Certificates")}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/contact-us">{t("Contact us")}</NavDropdown.Item>

              {/* <NavDropdown.Item as={Link} to="/careers">Careers</NavDropdown.Item> */}

              {/* TODO: news and events , careers
              
              <NavDropdown.Item href="#action/3.2">Certifications</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Approvals</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">Management</NavDropdown.Item>*/}



            </NavDropdown>
            <div className="nav-line"></div>
          </div>
          <div className="nav-wrapper">
            <NavDropdown title={t('HANSA-FLEX Group')} id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown.Item as={Link} to="/hf-group-management">{t('Management')}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hf-group-history">{t('History')}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hf-group-mission-statement">{t('Mission statement and values')}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/hf-60-years-of-hansaflex">{t('60 years of HANSA-FLEX')}</NavDropdown.Item>
              <Nav.Link href="https://www.hansa-flex.de/fr/entreprise/" target="_blank" rel="noopener noreferrer" className='drop-menu-item-link' style={{ fontSize: '1rem', marginLeft: '10%', fontWeight: 400, paddingLeft: 0 }}>
                {t('More information about the HANSA-FLEX Group')}
              </Nav.Link>
            </NavDropdown>
            <div className="nav-line"></div>
          </div>
          <div className="nav-wrapper">
            <NavDropdown title={t('Products')} id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown title={t('Hoses and hose lines')} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/hoses-and-hose-lines">{t('Hoses and hose lines')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hose-line-config">{t('Hose line configurator')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hydraulic-hoses">{t('Hydraulic hoses')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/metal-hoses">{t('Metal hoses')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Industrial-hoses">{t('Industrial hoses')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/performed-hoses">{t('Preformed hoses')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/ext-high-pfm-hoses">{t('Extremely high-pressure hoses')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Ptfe-hoses">{t('PTFE hoses')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hose-accessories">{t('Accessories')}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={t('Pipelines')} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/pipelines">{t('Pipelines')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pipebends">{t('Pipebends')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pipeend-processing">{t("Pipe-end processing")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Expansionjoints">{t("Expansion joints")}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={t("Drive and control technology")} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/drive-and-control-tech">{t("Drive and control technology")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pumps">{t('Pumps')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hydraulic-motor">{t('Hydraulic motors')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/cylinder">{t('Cylinders')}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/switches-and-sensors">{t('Switches and sensors')}</NavDropdown.Item>
                <NavDropdown title={t("Valve technology")} id="nested-nav-dropdown" drop='start'>
                  <NavDropdown.Item as={Link} to="/valve-tech">{t("Valve technology")}</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/pipeline-installation-valve">{t("Pipeline installation valves")}</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/cartridge-valve">{t("Cartridge valves")}</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/cetop-valve">{t("CETOP valves")}</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown.Item as={Link} to="/measuring-tech">{t("Measuring technology")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/seven-hundred-hydraulics">{t("700 bar heavy load hydraulics")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pressure-accumulators">{t("Pressure accumulators")}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={t('Hydraulic connection technology')} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/hydraulic-connection-technology">{t("Hydraulic connection technology")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hose-fitting">{t("Hose fittings")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pipe-fitting">{t("Pipe fittings")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/adapters">{t("Adapters")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/coupling">{t("Couplings")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/ball-valves">{t("Ball valves")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/flanges">{t("Flanges")}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown.Item as={Link} to="/sealing-tech">{t("Sealing technology")}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/filtration">{t("Filtration")}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/pneumatics">{t("Pneumatics")}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/technical-information">{t("Technical information")}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/approvals">{t("Approvals")}</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/catalogues">{t("Catalogues")}</NavDropdown.Item>

            </NavDropdown>
            <div className="nav-line"></div>
          </div>


          <div className="nav-wrapper">
            <NavDropdown title={t("Services")} id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown.Item as={Link} to="/mobile-rapid-hydraulic-service">{t("Mobile rapid hydraulic service")}</NavDropdown.Item>

              <NavDropdown title={t("Fluid management")} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/fluid-service">{t("Fluid management")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/oil-analysis">{t("Oil analysis")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/oil-filtering-and-cleaning">{t("Oil filtering and cleaning")}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={t("Assembly and installation")} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/assembly-and-installation">{t("Assembly and installation")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/modernisation-and-optimisation">{t("Modernisation and optimisation")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/plant-project-planning-and-management">{t("Plant project planning and management")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/installation-of-pipe-and-hoseline-systems">{t("Installation of pipe and hose line systems")}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={t("Preventative maintenance")} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/preventative-maintainance">{t("Preventative maintenance")}</NavDropdown.Item>
                <NavDropdown title={t("Hose management")} id="nested-nav-dropdown" drop='start'>
                  <NavDropdown.Item as={Link} to="/hose-management">{t("Hose management")}</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/x-code-hose-identification-system">{t("X-CODE hose identification system")}</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/x-code-manager">{t("X-CODE Manager")}</NavDropdown.Item>
                  {/* <NavDropdown title={t("X-CODE Manager")} id="nested-nav-dropdown" drop='start'>
                    <NavDropdown.Item as={Link} to="/licenses">{t("Licences")}</NavDropdown.Item>
                  </NavDropdown> */}
                  <NavDropdown.Item as={Link} to="/service-packages">{t("Service packages")}</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title={t("Inspections")} id="nested-nav-dropdown" drop='start'>
                  <NavDropdown.Item as={Link} to="/inspection">{t("Inspections")}</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/dguv-inspection">{t("DGUV inspection")}</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/industrial-hose-inspection">{t("Industrial hose inspection")}</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/lifting-platforms-and-loading-bridges">{t("Lifting platforms and loading bridges")}</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Pressureaccumulatorinspection">{t("Pressure accumulator inspection")}</NavDropdown.Item>
                </NavDropdown>
              </NavDropdown>

              <NavDropdown title={t("Repair and overhaul")} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/repair-and-overhaul">{t("Repair and overhaul")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hydraulic-cyclinder-repair">{t("Hydraulic cylinder repair")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/pressure-accumulators-and-associated-systems">{t("Pressure accumulators and associated systems")}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={t("Digital services")} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/DigitalServices">{t("Digital services")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Scannersolutions">{t("Scanner solutions")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/EDIandBMEcat">{t("EDI and BMEcat")}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={t("Production")} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/Production">{t("Production")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/SeriesProduction">{t("Series production")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Customisedassembly">{t("Customised assembly")}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={t("Logistics solutions")} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/Logisticssolutions">{t("Logistics solutions")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Warehousemanagement">{t("Warehouse management")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Kanbandelivery">{t("Kanban delivery")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Kitting">{t("Kitting")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Hydraulicworkshopcontainers">{t("Hydraulic workshop containers")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Worksinworks">{t("Works-in-works")}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title={t("Engineering")} id="nested-nav-dropdown" drop='end'>
                <NavDropdown.Item as={Link} to="/Engineering">{t("Engineering")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/EngineeredbyHansaflex">{t("Engineered by HANSA-FLEX")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Hydraulicunit">{t("Hydraulic unit manufacture")}</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Prototypemanufacture">{t("Prototype manufacture")}</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown.Item as={Link} to="/hydraulic-academy">{t("Training and seminars")}</NavDropdown.Item>
            </NavDropdown>
            <div className="nav-line"></div>
          </div>


          {/* <div className="nav-wrapper">
            <NavDropdown title="News and Events" id="basic-nav-dropdown" className="no-arrow" onSelect={handleDropdownClose}>
              <NavDropdown.Item as={Link} to="/iree">HANSA-FLEX at IREE </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/bauma">Bauma Conexpo India 2023 </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tree-plantation">Tree Plantation on Environment Day</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/product-training">Product training session</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/goethe-event">Goethe Event</NavDropdown.Item>

              {/*<NavDropdown.Item href="#action/3.2">Dropdown Item 2</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Dropdown Item 3</NavDropdown.Item>

            </NavDropdown>
            <div className="nav-line"></div> 
          </div>*/}


          {/* <div className="nav-wrapper">
            <NavLink as={Link} to="/careers">Careers</NavLink>
            <div className="nav-line"></div>
          </div> */}


          <hr />
          <div className="additional-nav-mobile-menu">
            <div className='d-flex align-items-center'>
              <FaLanguage />  <span className={`language-label ${!checked ? 'active' : ''}`} style={{ marginLeft: '15px' }}>En </span>
              <Switch
                checked={checked}
                onChange={handleChange}
                onColor="#4CAF50"
                offColor="#FF5722"
                onHandleColor="#fff"
                offHandleColor="#fff"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                height={22}
                width={48}
                className="language-switch"
              />
              <span className={`language-label ${checked ? 'active' : ''}`}> Fr</span>
            </div>
            <Nav.Link href="https://shop.hansa-flex.ca/en_GB/" target="_blank" rel="noopener noreferrer">
            <FaShoppingCart />  {t("Online-Catalogue")}

            </Nav.Link>
            <Nav.Link href="https://my.hansa-flex.ca/en_GB/login" target="_blank" rel="noopener noreferrer">
            <FaCode />  {t("X-CODE Manager")}

            </Nav.Link>
          </div>

        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MainNavbarMenu;
