import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import SectionHeader from '../SectionHeader/SectionHeader';


const FullTextButton = ({ title, paragraph, buttonColor, buttonLink, buttonText }) => {
    const buttonStyles = {
        backgroundColor: buttonColor,
        borderColor: buttonColor,
        boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)',
    };

    return (
        <div className="text-center div-gray">
            <SectionHeader title={title} paragraph={paragraph} />
            <br />
            <Button
                variant="primary"
                href={buttonLink}
                target="_blank"
                className="btn-full-button"
                style={buttonStyles}
            >
                {buttonText}
            </Button>
            <br /><br /><br />
        </div>
    );
};

FullTextButton.propTypes = {
    title: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    buttonColor: PropTypes.string,
    googleReviewsLink: PropTypes.string.isRequired,
};

FullTextButton.defaultProps = {
    buttonColor: '#ed4760', // Default to the light blue color
};

export default FullTextButton;
