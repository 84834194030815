import React, { useEffect } from 'react';
import HFTab from '../../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';

import Stage from '../../../../assets/Services/PreventativeMaintainance/ServicePackages/Beginers.jpg'
import Beginers from '../../../../assets/Services/PreventativeMaintainance/ServicePackages/Beginers.jpg'
import Software from '../../../../assets/Services/PreventativeMaintainance/ServicePackages/Software.jpg'
import UniqueIdentificaation from '../../../../assets/Services/PreventativeMaintainance/ServicePackages/UniqueIdentificaation.jpg'
import ProfessionalInstallation from '../../../../assets/Services/PreventativeMaintainance/ServicePackages/ProfessionalInstallation.jpg'

const ServicePackages = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    //HfTab data
    const imageTextData = [
        {
            title: "Basic service package 1: The free starter package",
            description: "The Basic service package is our solution for customers who would like to perform their own inspections, replace hose lines and record the information in the hose management software themselves. You receive access to our X-CODE Manager software (SMART licence for two users) and some initial training from one of our experts: We introduce the functions and support you in creating your customised hose line database. You and your employees do everything else yourselves, completely without our help. It costs nothing but it is worth quite a lot.",
            image: Beginers,
        },
        {
            title: "Plus service package: The comprehensive data package",
            description: "The Plus service package is our digital data package for your maintenance. We create your customised hose line database in the X-CODE Manager software, enter all the relevant data for you and keep the database up-to-date regularly. The result is your digital control centre for all hydraulic hose lines. One click, and you have all the important information at your fingertips. Of course, we also capture the data for your existing hose lines and give them an X-CODE.",
            image: Beginers
        },
        {
            title: "Premium service package: The all-round carefree package",
            description: "The Premium service package contains all the services in the Basic and Plus packages. In addition, we have our officially authorised persons for inspection carry out all the legally prescribed inspections and prepare the legally compliant documentation for you. You receive a printout for your machine logbook and a digital inspection record in your X-CODE Manager database, which you can recall and view at any time. As a result, you can check on the status of the hydraulics on your systems at any time and can concentrate your maintenance resources on other important matters.",
            image: Beginers
        },
        {
            title: "Pro service package: The extra portion service",
            description: "The Pro service package contains all the services in the Basic, Plus and Premium service packages. In addition, we make ourselves available to advise you on the risk analysis and the preparation of the legally prescribed risk assessment for every individual hose line. If necessary, our experts can also advise you on the inspection of components such as pressure accumulators. A professional daily oil analysis rounds off the range of services in the Pro package.",
            image: Beginers
        },
    ];

    const tabTitles = [
        { title: "For beginners", eventKey: "For beginners" },
        { title: "The data package", eventKey: "The data package" },
        { title: "All-round carefree", eventKey: "All-round carefree" },
        { title: "Extra service", eventKey: "Extra service" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"AVOID RISKS, KEEP MACHINES MAINTAINED"}
                    paragraph={"The hose management service package from HANSA-FLEX offers you made-to-measure services for the safe and legally compliant operation of your hydraulic systems. Whether you have the starter package, the digital or the all-round carefree package including inspection and documentation, the fixed flat rate daily price makes planning and cost estimates much easier. Choose the most suitable services for managing your hose lines."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PROCURE ONLY THE SERVICES YOU NEED."}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            {/* TODO:SERVICE PACKAGES */}

            <div>
                <SectionHeader
                    title={"PROFESSIONAL INSTALLATION ON SITE"}
                    subtitle={"Trained technicians replace and install your hydraulic hose lines wherever you need us."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Software}
                                title="The best software for your hydraulic systems"
                                desc={"X-CODE Manager is the hose line management software from HANSA-FLEX for the best overview of your hose lines, machines, scheduled dates and"} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={UniqueIdentificaation}
                                title="Unique identification and coding"
                                desc={"X-CODE hose coding ensures error-free replacement orders and forms the basis for hose management using the X-CODE Manager software."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={ProfessionalInstallation}
                                title="Professional installation on site"
                                desc={"Trained technicians replace and install your hydraulic hose lines wherever you need us."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default ServicePackages