import React, { useEffect } from 'react';
import './ContactUsCard.css'; // Import CSS file
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const ContactUsCard = ({ units }) => {
    const { t } = useTranslation();

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);


    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 320, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 300, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
    }, []);

    return (
        <div className="cards-container">
            {units.map((unit, index) => (
                <div
                    key={index}
                    className="contact-card"
                    data-aos={index % 2 === 0 ? (isMobile ? "fade-up" : "fade-right") : (isMobile ? "fade-up" : "fade-left")}
                    style={{
                        backgroundImage: `url(${unit.image})`,
                    }}
                >
                    <div className="content">
                        <h2 className="unit-name">{t(unit.name)}</h2>
                        {unit.description && <h6 className="description">{t(unit.description)}</h6>}

                        <div className="info">
                            <div>
                                <p className="label">{t("ADDRESS")}:</p>
                                {/* <p className="text">{unit.location}</p> */}
                                <p>
                                    <a
                                        className="text"
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent("Hansa-Flex France" + unit.location)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t(unit.location)}
                                    </a>
                                </p>
                            </div>
                            <div>
                                <p className="label">{t("TELEPHONE")}:</p>
                                {/* <p className="text">{unit.telephone}</p> */}
                                <p>
                                    <a
                                        className="text"
                                        href={`tel:${unit.telephone.replace(/\s+/g, '')}`}
                                    >
                                        {t(unit.telephone)}
                                    </a>
                                </p>
                            </div>
                            {unit.fax && (
                                <div>
                                    <p className="label">{t("FAX")}:</p>
                                    {/* <p className="text">{unit.fax}</p> */}
                                    <p>
                                        <a
                                            className="text"
                                            href={`tel:${unit.fax.replace(/\s+/g, '')}`}
                                        >
                                            {t(unit.fax)}
                                        </a>
                                    </p>
                                </div>
                            )}
                            <div>
                                <p className="label">{t("E-MAIL")}:</p>
                                {/* <p className="text">{unit.mail}</p> */}
                                <a
                                    className="text"
                                    href={`mailto:${unit.mail}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t(unit.mail)}
                                </a>
                            </div>
                        </div>
                        <button
                            className="copy-button"
                            onClick={() => {
                                navigator.clipboard.writeText(unit.mail);
                                alert(t('Email copied to clipboard!'));
                            }}
                        >
                            {t('Copy E-mail Address')}
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ContactUsCard;
