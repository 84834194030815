import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { FaMapMarkerAlt, FaPhone, FaLanguage, FaCode, FaShoppingCart, FaBookOpen } from 'react-icons/fa'; // Importing icons
import logo from '../../assets/logo.svg';
import './CustomNavbar.css';
import { Link } from 'react-router-dom';
import { MdOutlineMenuBook } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';

function CustomNavbar() {
  const [expanded, setExpanded] = useState(false);
  const { i18n, t } = useTranslation();
  const [checked, setChecked] = useState(i18n.language === 'fr');

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    const newLang = nextChecked ? 'fr' : 'en';
    i18n.changeLanguage(newLang);
  };

  return (
    <div>
      <Navbar className='white-bg margin-left' variant="light" expand="lg" expanded={expanded} >
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}

            className="d-inline-block align-top custom-nav-logo"
            alt="Logo"
          />
        </Navbar.Brand>

        <div className='custom-nav-menu'>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
          <div className='left-align-items'>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="c-nav-menu">
                {/* <Nav.Link href="#branch-finder">
                  <FaMapMarkerAlt /> branch-finder
                </Nav.Link> */}
                <Nav.Link href="/contact-us">
                  <FaPhone /> {t('Contact us')}
                </Nav.Link>
                <Nav.Link href="#">
                  <div className='d-flex align-items-center'>
                    <FaLanguage /><span className={`language-label ${!checked ? 'active' : ''}`} style={{ marginRight: '5px' }}>En </span>
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      onColor="#4CAF50"
                      offColor="#FF5722"
                      onHandleColor="#fff"
                      offHandleColor="#fff"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={22}
                      width={48}
                      className="language-switch"
                    />
                    <span className={`language-label ${checked ? 'active' : ''}`} style={{ marginLeft: '5px' }}> Fr</span>
                  </div>
                </Nav.Link>
                <Nav.Link href="https://shop.hansa-flex.ca/en_GB/" target="_blank" rel="noopener noreferrer">
                  {/* <FaShoppingCart /><MdOutlineMenuBook />  */}
                  <FaBookOpen /> {t('Online-Catalogue')}

                </Nav.Link>
                <Nav.Link href="https://my.hansa-flex.ca/en_GB/login" target="_blank" rel="noopener noreferrer">
                <FaCode />  {t('X-CODE Manager')}

                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>

        </div>

      </Navbar>
    </div>
  );
}

export default CustomNavbar;
