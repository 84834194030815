import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Stage.jpg'
import Assessment from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Assessment.jpg'
import Planning from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Planning.jpg'
import Implementation from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Implementation.jpg'
import Services from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Services.jpg'
import HFIndustrialService from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/HFIndustrialService.jpg'
import HFFluidService from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/HFFluidService.jpg'
import Innovation from '../../../assets/Services/AssemblyAndInstallation/ModernizationAndOptimization/Innovation.jpg'


const ModernisationAndOptimisation = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: Assessment,
        title: 'Assessment of your hydraulic system',
        description: 'Renovation can often but not always be a quick and cost-effective alternative to purchasing a completely new system. Only if the rest of the equipment is in sound condition is it worthwhile to recondition or upgrade the hydraulics. However, that is not the only reason why we carefully assess your hydraulic system. We also check factors such as energy consumption and productivity. After considering all these aspects, a prudent judgement can be made on whether retrofitting promises better value than purchasing new equipment. The assessment also shows which parts of the system have the potential to be optimised and how it could be done.',
    }

    const data2 = {
        image: Planning,
        title: 'Planning your renovation project',
        description: "Our experts work with you to draw up a list of requirements. What are the possible efficiency gains? What standards have to be fulfilled? How much energy should be saved? Are there performance parameter values you would like or need to achieve? Based on the answers to these questions we plan the renovation and alteration work, select suitable components and draw up a time and cost schedule. You can always count on the specialist knowledge of our experts in the field of control, pipeline and plant technology.",
    }

    const data3 = {
        image: Implementation,
        title: 'Implementation of renovation projects',
        description: 'You can completely rely on the experienced and excellently trained service technicians from HANSA-FLEX Industrial Service for the implementation of all renovation and retrofitting projects. They have comprehensive specialist knowledge and years of practical experience. Regular professional development training ensures the consistently high quality of the work they perform. Whether control technology or installing pipes and hoses, all the work is carried out to the customary HANSA-FLEX level of quality. We do what we promise, and the quality speaks for itself.',
    }

    const data4 = {
        image: Services,
        listItems: [
            "Fault location and analysis",
            "Economic analysis of renovation projects",
            "Agreement on developing a suitable customised solution",
            "Planning and advice about the renovation project",
            "Carrying out of all work related to fluid technology"
        ]
    }

    const featuresData = [
        {
            title: "Assessment and transparent advice",
            description: "Our experts properly assess your system and offer fair and competent advice.",
            image: imgMark
        },
        {
            title: "Careful planning",
            description: "Before work starts on renovation, we draw up a detailed project plan including milestones",
            image: imgMark
        },
        {
            title: "Standards and quality",
            description: "Our experts observe all standards and deliver proven HANSA-FLEX quality.",
            image: imgMark
        },
        {
            title: "Everything from a single source",
            description: "Excellently trained technicians and the highest material availability.",
            image: imgMark
        }
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"RETROFITTING AND RENOVATING HYDRAULIC SYSTEMS"}
                    paragraph={"Retrofitting or renovating a hydraulic system can often be an effective alternative to purchasing a new one. It is also a way to raise energy efficiency and productivity or comply with new standards. Our experienced experts advise and assist you from the initial assessment of your existing system to the design and implementation of your retrofitting or renovation project."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'RETROFITTING AND OPTIMISATION IN ONE COMPLETE PACKAGE'}
                    subtitle={'From analysis to implementation: Benefit from an upgrade or optimisation of your system with everything provided by the same supplier'}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={'PRODUCTION STOPPAGES CAN BE PREVENTED AT THE TIME OF PURCHASE'}
                    subtitle={'The fast pace of innovation and electrification in the field of machinery and equipment has meant that key components, such as spare parts or software, are no longer available only a few years after purchase. As part of obsolescence management, we seek to maximise the useful life of your hydraulic systems.'}
                />
            </div>

            <div>
                <SectionHeader
                    title={"RETROFITTING HYDRAULIC SYSTEMS TO THE LATEST TECHNOLOGICAL STANDARDS"}
                    subtitle={"Retrofitting and renovating hydraulic systems with advice and support from HANSA-FLEX offers numerous advantages and provides true added value for your company."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}

            <div>
                <SectionHeader
                    title={'OUR SERVICES FOR RETROFITTING AND OPTIMISING'}
                    subtitle={"As a system partner, we provide extensive support to you on all aspects of optimisation and renovation of your machines and systems, with the aim of increasing efficiency and productivity."}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"WHAT EVERYONE SHOULD KNOW ABOUT FLUID MANAGEMENT"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={HFIndustrialService}
                                title="HANSA-FLEX Industrial Service"
                                desc={"Our experienced service technicians in the HANSA-FLEX Industrial Service team support you with all servicing, maintenance and installation work on mobile and"} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={HFFluidService}
                                title="HANSA-FLEX Fluid Service"
                                desc={"Regular oil care is a key aspect in ensuring trouble-free operation of hydraulic systems and plants."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Innovation}
                                title="Innovation and manufacture"
                                desc={"As a system partner, HANSA-FLEX provides you with a full range of engineering services, from system development and design right up to implementation, all from a single"} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>

        </div>
    )
}

export default ModernisationAndOptimisation