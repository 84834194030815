import React, { useEffect } from 'react';
import imgPerformedHoses from '../../../assets/Products/HosesAndHoseLines/performed Hoses/performedHoses.jpg'
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgHFfrom from '../../../assets/Products/HosesAndHoseLines/performed Hoses/HFfrom.jpg'
import imgIndividual from '../../../assets/Products/HosesAndHoseLines/performed Hoses/individual.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgHighPerformance from '../../../assets/Products/HosesAndHoseLines/performed Hoses/highPerformance.jpg'
import imgPreformedHoseRequirement from '../../../assets/Products/HosesAndHoseLines/performed Hoses/PreformedHoseRequirement.jpg'
import imgMadeToMeasure from '../../../assets/Products/HosesAndHoseLines/performed Hoses/MadeToMeasure.jpg'
import imgHoseForApplication from '../../../assets/Products/HosesAndHoseLines/performed Hoses/HoseForApplication.jpg'
import imgXtrHighPressureHose from '../../../assets/Products/HosesAndHoseLines/performed Hoses/XtrHighPressureHose.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';



function PerformedHoses() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const MadeTOMeasure1 = {
        image: imgHFfrom,
        title: 'HANSA-FLEX from design to series production readiness',
        description: 'HANSA-FLEX has a large portfolio of standard preformed hoses and offers full-service solutions for customers who require individually manufactured preformed hoses to suit their precise requirements:',
        listItems: ["Designed by the HANSA-FLEX engineering team", "From prototyping to series production", "Optional digital determination of the XYZ data"]
    }

    const MadeToMesure2 = {
        image: imgIndividual,
        title: 'Individual preformed hoses for all geometries',
        description: 'Preformed hoses must be designed and manufactured perfectly to suit their intended installation situation. Therefore, the first step is to make shaped mandrels based on the required final hose geometry in steel or stainless steel. The material for the preformed hose is then wound or drawn onto and takes up the precise geometry of the shaped mandrel. Several layers can be applied so that, for example, the inner layer of the preformed hose meets the customer’s specified media resistance requirements. Preformed hoses can be reinforced with textile or metal braid. Metal components can be vulcanised into the layers or rubber components vulcanised onto them to allow even more scope for hose design and attachment arrangements.',
    }

    const MadeToMesure3 = {
        image: imgPreformedHoseRequirement,
        listItems: ["Individualised solutions for almost any degree of bend", "Extruded or mandrel-wrapped to suit the specific application", "Reinforced with polyester or rayon fabric inserts", "Preformed hoses can incorporate several inlets/outlets"]
    }

    const HighPerformance1 = {
        image: imgHighPerformance,
        title: 'High performance from preformed hoses',
        description: 'HANSA-FLEX fits preformed hoses for fuel, coolant water and turbocharger air supply to the 320 HP Marine Hypercraft jet ski manufactured by Belassi. The preformed hoses not only have to resist the high temperatures directly on the engine, corrosive saltwater and solar radiation but also withstand the mechanical loads placed on them during the ride. Speeds of up to 120 km/h and jumping as high as 5 m into the air place special demands on hoses.',
    }

    const performedHosesFrom = [
        {
            title: "Free-form",
            description: "HANSA-FLEX produces individualised solutions with bends of almost any radius.",
            image: imgMark
        },
        {
            title: "Highly load resistant",
            description: "Reinforcing layers for high pressure or vacuum applications can be incorporated on request.",
            image: imgMark
        },
        {
            title: "High temperature resistance",
            description: "Operating temperature ranges between -40 °C to +320 °C, depending on the material mixture.",
            image: imgMark
        },
        {
            title: "Material mixture qualities confirmed in tests",
            description: "For example, certified mixtures in accordance with DIN 73411 or fire protection standards DIN 5510 / EN 45545.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgPerformedHoses}
                    title={"HANSA-FLEX PREFORMED HOSES FOR HIGH FLEXIBILITY IN THE TIGHTEST SPACES"}
                    paragraph={"Preformed hoses are generally called for where conventional hose lines cannot be used because of restricted installation space. For example, in situations where a hose carrying fuel or coolant in engines or batteries would otherwise require to be bent sharply, preformed hoses from HANSA-FLEX provide an effective and cost-efficient solution. They are highly elastic, very robust and can be made to almost any shape."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"MADE-TO-MEASURE PREFORMED HOSES FROM HANSA-FLEX"}
                />
                <div>
                    <ImageText
                        data={MadeTOMeasure1} />
                    <ImageText
                        data={MadeToMesure2}
                        left={false} />
                </div>
            </div>

            <div>
                <div>
                    <SectionHeader
                        title={"PREFORMED HOSES FROM HANSA-FLEX, YOUR INNOVATIVE SYSTEM PARTNER"}
                        subtitle={"The HANSA-FLEX experts with their years of experience and comprehensive knowledge of the industry can always determine the perfect shape and material mixture for any complex customer requirement."}
                    />

                    <FeatureCardCarousel
                        featuresData={performedHosesFrom} />
                </div>
            </div>

            {/* TODO: Carousal */}
            {/* TODO: Client Review */}

            <div>
                <ImageText
                    data={HighPerformance1} />
            </div>

            <div>
                <SectionHeader
                    title={"PREFORMED HOSES MADE EXACTLY TO THE CUSTOMER’S REQUIREMENTS"}
                    subtitle={"HANSA-FLEX perfectly matches the preformed hoses ordered by the customer to the dimensions, material requirements and field of application."}
                />
                <ImageText
                    data={MadeToMesure3}
                    left={false}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMadeToMeasure} learnMoreLink={"/pipelines"}
                                title="Made-to-measure pipelines as one-off, small batch or series manufactured items"
                                desc={"HANSA-FLEX manufactures application-specific pipelines as one-off items or in batches to dimensional tolerances given in DIN ISO 2768-1."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseForApplication} learnMoreLink={"/Industrial-hoses"}
                                title="Industrial hoses for every application"
                                desc={"HANSA-FLEX’s specialist hose workshop produces hoses for every application. The products are, of course, manufactured to the highest quality and delivered quickly."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgXtrHighPressureHose} learnMoreLink={"/ext-high-pfm-hoses"}
                                title="Extremely high-pressure hoses to meet the highest requirements"
                                desc={"With working pressures up to 3,200 bar, HANSA-FLEX’s extremely high-pressure hoses for hydraulics, water jetting, oil and gas applications offer safety even under..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    );
}

export default PerformedHoses;
