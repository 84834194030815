import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageText from '../../../Components/ImageText/ImageText'
import HFTabs from '../../../Components/HfTabs/HfTabs'


import Stage from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/Stage.jpg'
import HighFlow from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/HighFlow.jpg'
import ForAllApplication from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/ForAllApplication.jpg'
import RobustMaterial from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/RobustMaterial.jpg'
import ScrewCoupling from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/ScrewCoupling.jpg'
import PlugInCoupling from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/PlugInCoupling.jpg'
import TemperatureControl from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/TemperatureControl.jpg'
import MultiCoupling from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/MultiCoupling.jpg'
import CouplingForAgri from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/CouplingForAgri.jpg'
import SafetyBonus from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/SafetyBonus.jpg'
import PipeCoupling from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/PipeCoupling.jpg'
import Accessories from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/Accessories.jpg'
import ThaLarge from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/ThaLarge.jpg'
import BallValve from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/BallValve.jpg'
import RightConnection from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/RightConnection.jpg'
import LeakProof from '../../../assets/Products/HydraulicConnectionTechnology/Couplings/LeakProof.jpg'


const Couplings = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const featuresData = [
        {
            title: "Robust design",
            description: "The flat-face sealing hydraulic couplings are ideal for use in harsh conditions.",
            image: imgMark
        },
        {
            title: "Certified quality",
            description: "HANSA-FLEX hydraulic couplings meet the requirements of standards ISO 7241, ISO 16028 and ISO 14541.",
            image: imgMark
        },
        {
            title: "Quick installation",
            description: "The couplings are quick to install, allowing users to gain from short re-equipment times.",
            image: imgMark
        },
        {
            title: "Excellent availability",
            description: "We maintain an extensive stock of products and suitable accessories for your convenience.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    const Data1 = {
        title: 'High flow and minimum pressure loss',
        description: 'Hydraulic couplings from HANSA-FLEX allow hydraulic equipment to be connected quickly, safely and leak-free. Coupling sleeves and plugs can be pushed in or screwed together depending on the coupling type. When the hydraulic connection is separated, valves on both sides automatically seal the open connections. Even filled hydraulic hoses can be detached in this environmentally friendly and clean manner without loss of hydraulic oil. With their flow-optimised cross section, HANSA-FLEX quick release couplings also avoid pressure losses, the development of heat, friction wear, cavitation and premature ageing of the pressure fluid.',
        image: HighFlow
    }

    const Data2 = {
        title: 'Couplings for all applications',
        description: 'Couplings are an essential element of connection technology in factories, in fields and forests as well as on construction sites. Which coupling is the most suitable depends on the individual application. The hydraulic experts at HANSA-FLEX help and advise you on all aspects of the selection and design all your hydraulic couplings right up to questions of installation.',
        listItems: ["All technical parameters, such as design flows, are taken into account", "Wide product portfolio for many areas of use", "For applications in stationary and mobile hydraulics"],
        image: ForAllApplication
    }

    const Data3 = {
        title: 'Robust materials',
        description: 'Hydraulic couplings are continually coupled and decoupled, which places them under heavy load. HANSA-FLEX therefore makes no compromise in meeting the highest quality standards for all its hydraulic couplings, which impress users with their long service life and low leakage.',
        listItems: ["Manufactured from steel for superior functionality even at high pressures", "Also available in stainless steel or brass to suit the intended purpose", "A surface coating provides high corrosion resistance"],
        image: RobustMaterial
    }

    const Data4 = {
        title: 'Safety bonus from quick release couplings',
        description: 'Quick release couplings decouple automatically if the longitudinal tensile load becomes too great and thus prevent the hose lines from being pulled out and the resulting uncontrolled leakage of hydraulic oil.',
        listItems: ["Increased passive safety when shunting on rails or manoeuvring on water", "Protects hose lines with low tensile strength", "Made from steel or malleable iron, galvanised zinc surface coating", "Suitable for mineral and bio-oils"],
        image: SafetyBonus
    }

    const Data5 = {
        title: 'Pipe couplings for high pressures',
        description: 'Pipe couplings are designed for high pressures and pressure impulses. Flat-face sealing plungers prevent air entry and ensure low oil leakage losses. Their robust construction makes pipe couplings suitable for demanding conditions of use with large pressure fluctuations. They therefore find widespread application in the automotive industry.',
        listItems: ["Connection and disconnection without leaks or air entry", "Coupled by screwing together sleeve and plug", "Coupling and decoupling possible up to 20 bar"],
        image: PipeCoupling
    }

    const Data6 = {
        title: 'Accessories perfectly matched to your hydraulic couplings',
        description: 'Accessories for hydraulic couplings such as dust protection caps or seals protect your hydraulic couplings from dirt and prevent foreign particles from entering and circulating within your hydraulic systems. HANSA-FLEX has attachment flanges and welded-on brackets available for optimum placement and secure fastening of couplings to tractors or machinery.',
        listItems: ["Dust and dirt protection devices such as dust protection caps and covers", "Welded-on and attachment flanges for secure fastening of the quick release coupling fixed halves"],
        image: Accessories
    }

    const Data7 = {
        listItems: [
            "Variants include fixed and loose coupling halves, sleeves and plugs",
            "Shut-off at one or both ends, flat face seal and can be coupled/decoupled under pressure",
            "Captive dust protection caps cannot be inadvertently lost",
            "Available in all commercially available thread types, such as BSP, NPT and UNF",
            "Materials: steel, stainless steel and brass",
            "Surface: chromium-VI-free and galvanised coatings"
        ],
        image: ThaLarge
    }

    //HfTab data
    const imageTextData = [
        {
            title: 'Screw couplings are the solution for extreme usage',
            description: "Their robust construction allows screw couplings to withstand high pressures and pressure impulses. They are designed for the harshest conditions in industry and agriculture.",
            listItems: ["Can be coupled to tools with one side under pressures up to 100 bar", "Standardised hydraulic screw couplings in accordance with ISO 14541", "Protected from premature wear potentially caused by high pressure impulses", "Self-securing threads prevent unintentional decoupling", "Excellent seal from a conical valve with an elastomer seal"],
            image: ScrewCoupling
        },
        {
            title: 'Low-drip coupling and decoupling with plug-in coupling',
            description: "Valves seal the plug-in coupling connections when the coupling sleeve is drawn back and prevent loss of hydraulic oil. The double O-ring seal ensures maximum sealing performance for the coupling. A safety locking ring prevents involuntary disconnection. Flat-face sealing versions of push-in couplings are available from HANSA-FLEX. These hydraulic couplings limit the entry of air to a minimum when coupling and thus avoid oil leaks.",
            image: PlugInCoupling
        },
        {
            title: 'Temperature control couplings for hot and cold',
            description: "Temperature control couplings are specially designed for use with liquid media at temperatures of -20 °C to +160 °C such as those found in plastic injection moulding systems. The quick release couplings have a locking protection device to keep people safe and prevent damage to the environment from unintentional escape of hot or cold media such as heat transfer oil, hot or cold water.",
            listItems: [
                "Suitable for use at temperatures of -20 °C to 160 °C",
                "A locking protection device prevents loss of fluid or pressure",
                "Shut-off at one or both ends"
            ],
            image: TemperatureControl
        },
        {
            title: 'Everything under control using multi-couplings',
            description: "Multi-couplings allow several lines to be connected or disconnected quickly and are frequently used with removable attachments in agriculture, forestry and construction.",
            listItems: [
                "Quick and easy exchange of removable attachments",
                "Easy operation with hand lever",
                "Trouble-free coupling even if the system is under pressure",
                "No confusion of connections or flow paths"
            ],
            image: MultiCoupling
        },
        {
            title: 'HANSA-FLEX agricultural couplings for excellent performance',
            description: "The coupling system developed by HANSA-FLEX for flows of up to 240 l/min is intended for powerful machinery used in agriculture, such as high-performance tippers. The plugs, wrench sizes and connection variants comply with ISO-7241, Series A (BG3), which means the one-handed coupling sleeve does not require modification to be compatible with other rigid mounted couplings available on the market.",
            listItems: [
                "Coupling under full working pressure requires very little physical effort",
                "High-flow valve unit prevents erosion",
                "Reliable pull-out protection and mechanical engagement system",
                "Compact dimensions"
            ],
            image: CouplingForAgri
        }
    ];

    const tabTitles = [
        { title: "Screw couplings", eventKey: "Screw couplings" },
        { title: "Plug-in couplings", eventKey: "Plug-in couplings" },
        { title: "Temperature control couplings", eventKey: "Temperature control couplings" },
        { title: "Multi-couplings", eventKey: "Multi-couplings" },
        { title: "Couplings for agriculture", eventKey: "Couplings for agriculture" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"SAFE HYDRAULIC COUPLINGS FROM HANSA-FLEX"}
                    paragraph={"Couplings are the best option in situations where attachments need to be quickly exchanged, machines safely connected or highly loaded hose lines on mobile and fixed hydraulic systems replaced. At HANSA-FLEX you can find quick release couplings perfect for your intended application in various materials such as steel or stainless steel and in a wide range of sizes and with various seal types."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"RELIABLE COUPLING AND UNCOUPLING"}
                    subtitle={"With quick release hydraulic couplings from HANSA-FLEX, you can connect and disconnect hydraulic components simply, swiftly and safely."}
                />
                <ImageText
                    data={Data1} />
                <ImageText
                    data={Data2}
                    left={false} />
                <ImageText
                    data={Data3} />
            </div>

            <div>
                <SectionHeader
                    title={"QUICK AND SAFE COUPLING"}
                    subtitle={"HANSA-FLEX hydraulic couplings come into their own where a simple way of connecting and disconnecting hydraulic components is needed. They are sealed and do not drip, allow high flows and residual pressures in the hydraulic lines."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"SIMPLE TO USE, RELIABLE IN OPERATION"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"MORE COUPLINGS FROM HANSA-FLEX"}
                    subtitle={"Safe hydraulic couplings for every application"}
                />
                <ImageText
                    data={Data4} />
                <ImageText
                    data={Data5}
                    left={false} />
                <ImageText
                    data={Data6} />
            </div>

            <div>
                <SectionHeader
                    title={"THE LARGE HANSA-FLEX RANGE OF COUPLINGS"}
                    subtitle={"You can find a large selection of reliable hydraulic couplings of various types for every purpose in our online shop."}
                />
                <ImageText
                    data={Data7} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={BallValve}
                                title="Low-maintenance ball valves"
                                desc={"HANSA-FLEX ball valves are used to shut off and divert flow at pressures of up to 500 bar and thus avoid the need to convert or uninstall components."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={RightConnection}
                                title="The right connection with hydraulic adapters"
                                desc={"HANSA-FLEX offers an extensive portfolio of hydraulic adapters, including threaded, transition or hose end adapters in all common connection forms and meeting all..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={LeakProof}
                                title="Leak-proof positive connection with standardised pipe fittings"
                                desc={"Pipe fittings from HANSA-FLEX rely on a tight positive fit with one another to create a safe connection even when subjected to high working pressures, pressure peaks and..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Couplings