import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgExpansion_centerstage from '../../../assets/Products/Pipelines/Expansion joints/Expansion_centerstage.jpg'
import ImageText from '../../../Components/ImageText/ImageText'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgExpansion_imagetext_01 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagetext_01.jpg'
import imgExpansion_imagetext_02 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagetext_02.jpg'
import imgExpansion_imagetext_03 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagetext_03.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgExpansion_imagecard_01 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagecard_01.jpg'
import imgExpansion_imagecard_02 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagecard_02.jpg'
import imgExpansion_imagecard_03 from '../../../assets/Products/Pipelines/Expansion joints/Expansion_imagecard_03.jpg'
import { Container, Row, Col } from 'react-bootstrap';

const ExpansionJoints = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);


  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Expansion_imagetext_01 = {
    image: imgExpansion_imagetext_01,
    title: 'Large range of types and materials',
    description: "HANSA-FLEX supplies round, square and special expansion joints of all types for clamp fastenings, with backing flanges or bellows. We can also make similar products for special applications using various materials from our large range.",
    listItems: ['Numerous materials such as silicone, PTFE, NBR and stainless steel', 'Large temperature range of -196 °C to +800 °C', 'For accommodating axial, transverse and angular expansion movements'],
  }

  const Expansion_imagetext_02 = {
    image: imgExpansion_imagetext_02,
    title: 'Individually manufactured solutions for your application',
    description: "Standard expansion joints are the exception rather than the rule. Elastomeric expansion joints are the only ones for which an official standard exists. However, HANSA-FLEX normally supplies expansion joints specifically for each application. Our specialists calculate the parameters based on the anticipated movement and design the expansion joint accordingly.",
    listItems: ['Nominal diameters from DN 10 to DN 5,000 mm', 'Pressures up to 25 bar', 'Broad range of connections'],
  }


  const Expansion_imagetext_03 = {
    image: imgExpansion_imagetext_03,
    title: 'Special applications and numerous approvals',
    description: "Expansion joints from HANSA-FLEX are the first choice for pipelines carrying cold or hot water, steam, petrol, oil, gases or chemical products. Special applications such as chemical equipment or fume extraction is another area where HANSA-FLEX puts its wide experience and skill base to successful use. This is confirmed by numerous approvals.",
    listItems: ['Approvals for DVGW, DGRL, material/radiographic inspections, DNV', "Manufacture of specially shaped components such as elbows and funnels", "Advice from highly competent HANSA-FLEX staff directly on site"],
  }

  return (
    <div>
      <CenterStage
        imageSrc={imgExpansion_centerstage}
        title={"SAFE SYSTEMS WITH EXPANSION JOINTS FROM HANSA-FLEX"}
        paragraph={"Expansion joints reliably accommodate changes in length and angle brought about by temperature differences, settlement of foundations or imprecise installation. They protect hose lines, machines and equipment from damage. Expansion joints also play a key role in damping vibrations from motors and turbines by preventing their transmission along exhaust pipes or compressed air lines."}
      />

      <SectionHeader
        title={"EXPANSION JOINTS FROM HANSA-FLEX FOR ALL APPLICATIONS"}
        subtitle={""}
      />

      <div >

        <ImageText data={Expansion_imagetext_01} left={false} />
      </div>

      <div >

        <ImageText data={Expansion_imagetext_02} />
      </div>

      <div >

        <ImageText data={Expansion_imagetext_03} left={false} />
      </div>

      {/* TODO: Carousel */}

      <SectionHeader
        title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
      />

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgExpansion_imagecard_01} learnMoreLink="/hydraulic-hoses" title="HANSA-FLEX flange fittings are the ideal fittings for large" desc="HANSA-FLEX flange fittings are ideal for large diameters, high-pressure applications and restricted installation situations." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgExpansion_imagecard_02} learnMoreLink="/metal-hoses" title="Made-to-measure bent pipes" desc="Precision pipes in proven HANSA-FLEX quality are manufactured made-to-measure out of various materials in the HANSA-FLEX pipe bending centre." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgExpansion_imagecard_03} learnMoreLink="/Industrial-hoses" title="Connected by pipe fittings" desc="Transitions between various components are always a point for careful consideration in hydraulic systems. Pipe fittings from HANSA-FLEX create a reliable connection." />
          </Col>

        </Row>
      </Container>

    </div>
  )
}

export default ExpansionJoints