import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import stringSimilarity from "string-similarity";

async function initializeI18n() {

    i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            debug: false,
            fallbackLng: "en",
            returnObjects: true,
            backend: {
                loadPath: '/locales/{{lng}}/translation.json',
            },
            saveMissing: true,
            missingKeyHandler: (lngs, ns, key, fallbackValue) => {
                // const currentLanguage = i18n.language;

                // if (currentLanguage === 'en') {
                //     // console.warn(`Missing translation key '${key}' in language '${currentLanguage}'`);
                //     return fallbackValue; // Skip custom logic for 'en' language
                // }



                // const similarKey = findSimilarKey(key);
                // let result = fallbackValue;

                // if (similarKey) {
                //     console.warn(`DEBUG i: Loosely matched translation key '${key}' with '${similarKey}'`);
                //     result = translationMap.get(similarKey);
                // } else {
                //     console.log(`DEBUG irr: mtk: '${key}'`);
                // }
                // if (similarKey === key) {
                //     console.log(`DEBUG i: mtksim: '${key}'`);
                // }
                // console.warn(`DEBUG ir: Returning translation: ${result}`);
                // return result;
            },
        });
}


export default initializeI18n;
