import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/Pumps/Stage.jpg'
import imgGearPumps from '../../../assets/Products/DriveAndControlTechnology/Pumps/GearPumps.jpg'
import imgAxialPistonPumps from '../../../assets/Products/DriveAndControlTechnology/Pumps/AxialPistonPumps.jpg'
import imgRadialPiston from '../../../assets/Products/DriveAndControlTechnology/Pumps/RadialPiston.jpg'
import imgGearPump from '../../../assets/Products/DriveAndControlTechnology/Pumps/GearPump.jpg'
import imgAxialPistonPumps2 from '../../../assets/Products/DriveAndControlTechnology/Pumps/AxialPistonPumps2.jpg'
import imgRadialPistonPump2 from '../../../assets/Products/DriveAndControlTechnology/Pumps/RadialPistonPump2.jpg'
import imgVanePump from '../../../assets/Products/DriveAndControlTechnology/Pumps/VanePump.jpg'
import imgGearFLowDividers from '../../../assets/Products/DriveAndControlTechnology/Pumps/GearFLowDividers.jpg'
import imgTraining from '../../../assets/Products/DriveAndControlTechnology/Pumps/Training.jpg'
import imgRepairAndOverhaul from '../../../assets/Products/DriveAndControlTechnology/Pumps/RepairAndOverhaul.jpg'
import imgValveTech from '../../../assets/Products/DriveAndControlTechnology/Pumps/ValveTech.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import { Col, Container, Row } from 'react-bootstrap';




const Pumps = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const pump1 = {
        image: imgGearPumps,
        title: 'Gear pumps are cost-effective and robust',
        description: 'HANSA-FLEX gear pumps are robust, cost-effective, simple in construction and straightforward in use. This is why they are found in numerous areas of mobile and industrial hydraulics.',
        listItems: ["Cost-effective fixed displacement pumps", "Displacements from 0.17 cm³ to 74.5 cm³", "Operating pressure from 240 bar to 280 bar"],
        buttonName: "ALL GEAR PUMPS"
    }

    const pump2 = {
        image: imgAxialPistonPumps,
        title: 'Axial piston pumps can be used for applications well into the high pressure range',
        description: 'Axial piston pumps are available as fixed and as variable displacement pumps. They are capable of creating high pressures and impress with their compact design.',
        listItems: ["Creation of high pressures up to 350 bar", "High displacement from 12 cm³ to 130 cm³", "Low pulsation in flow"],
        buttonName: "ALL AXIAL PISTON PUMPS"
    }

    const pump3 = {
        image: imgRadialPiston,
        title: 'Radial piston and vane pumps',
        description: 'Our range of products includes radial piston pumps and vane pumps for special useage scenarios. They are capable of creating pressures up to 500 bar for example.',
        listItems: ["Radial piston pumps", "Vane pumps", "Additional special solutions on request"],
    }

    const pump4 = {
        image: imgGearFLowDividers,
        title: 'Gear flow dividers for hydraulic applications',
        description: 'Flow dividers work using the same principle as a gear pump. The gears are connected to one another on a shaft, rotate at the same speed and move equal quantities of oil. This allows, for example, two circuits to be supplied with oil from one pump. HANSA-FLEX has flow dividers available in sizes 0 to 2, 2-way and 4-way versions, with flows of 0.7 to 25.9 l/min per element and optionally with pressure limiting and phase correction valves.',
        buttonName: "ALL FLOW DIVIDERS"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Proven quality",
            description: "With us you can count on receiving hydraulic pumps in the proven HANSA-FLEX quality.",
            image: imgMark
        },
        {
            title: "Advice from hydraulics specialists",
            description: "Our experts advise you and find the optimum solution for your applications.",
            image: imgMark
        },
        {
            title: "Excellent parts availability",
            description: "Our warehouse and logistics concept can supply pumps and accessories on a Just-In-Time basis.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Cost-efficient and robust gear pumps",
            description: "The type of design means the flow is not completely free of pulsation. On the other hand, this form of construction makes gear pumps very robust, cost effective and low maintenance.",
            image: imgGearPump
        },
        {
            title: "Low-vibration, efficient axial piston pumps",
            description: "In an axial piston pump, the piston rods are arranged in a circle on a swash plate and can move freely in a ball cup bearing. When the swash plate rotates, the opposite ends of the pistons rotate past the hydraulic oil inlet and outlet. All the piston rods move continuously due to the tilted surface of the swash plate. The greater the angle of tilt, the further down the cylinders the piston rods move and the higher is the resulting volumetric flow. Axial piston pumps are available in two versions: bent axis pumps with a fixed volumetric flow and variable pumps which allow the volumetric flow to be regulated. Their more technically complex design results in higher procurement costs and greater energy consumption.",
            image: imgAxialPistonPumps2
        },
        {
            title: "Radial piston pumps for high pressures",
            description: "Radial piston pumps work in a similar way to axial piston pumps. With radial piston pumps, however, the pistons are not arranged parallel to the drive axis but radially in a circle around it. The pump cylinder block is offset from the shaft axis. The resulting eccentric motion creates the pumping effect. Radial piston pumps can create very high pressures and are very efficient. However, they need to be solidly constructed to withstand the high pressure and therefore require more installation space.",
            image: imgRadialPistonPump2
        },
        {
            title: "Vane pumps for high volumetric flow",
            description: "Vane pumps work efficiently, cause only slight pulsations and are very quiet. Compared to other pump types, they are subject to high wear and therefore require more maintenance.",
            image: imgVanePump
        },
    ];

    const tabTitles = [
        { title: "Gear pump", eventKey: "Gear pump" },
        { title: "Axial piston pump", eventKey: "Axial piston pump" },
        { title: "Radial piston pump", eventKey: "Radial piston pump" },
        { title: "Vane pump", eventKey: "Vane pump" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"THE OPTIMUM HYDRAULIC PUMP FOR ANY APPLICATION"}
                    paragraph={"Hydraulic pumps are the heart of every hydraulic system. The build up the pressure in the system and take the energy of the oil to where it is needed. Our extensive range of gear and axial piston pumps in proven HANSA-FLEX quality covers a wide field of requirements in industrial and mobile hydraulics. Our product portfolio also contains radial piston and vane pumps for special applications."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THE HANSA-FLEX PUMP PORTFOLIO FOR HYDRAULICS"}
                    subtitle={"With HANSA-FLEX, you gain from reliable, long-lasting solutions and the rapid delivery of all the required components."}
                />
                <ImageText
                    data={pump1} />

                <ImageText
                    data={pump2}
                    left={false} />
                <ImageText
                    data={pump3} />
            </div>
            <div>
                <SectionHeader
                    title={"WHICH HYDRAULIC PUMP IS RIGHT FOR YOU?"}
                    subtitle={"We advise and assist you in making the right choice of hydraulic pump. How much pulsation can your system tolerate? What displacement and pressures are needed? Our experts will be pleased to advise you and provide special solutions if required."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"YOUR ADVANTAGES WITH HANSA-FLEX HYDRAULIC PUMPS"}
                    subtitle={"Benefit from our extensive product portfolio of hydraulic pumps, good availability and competent advice from our fluid experts."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}

            <div>
                <SectionHeader
                    title={"OTHER HYDRAULIC UNIT COMPONENTS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <ImageText
                    data={pump4} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgTraining}
                                title="Training from the experts"
                                desc={"In cooperation with the International Hydraulics Academy (IHA), HANSA-FLEX offers a wide range of courses and seminars on fluid technology."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgRepairAndOverhaul}
                                title="Repair and overhaul of hydraulic components"
                                desc={"HANSA-FLEX experts can repair and overhaul motors, pumps and cylinders of all sizes and from any manufacturer."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgValveTech}
                                title="Valve technology for pressures up to 350 bar"
                                desc={"With high-quality valves from HANSA-FLEX, you benefit from excellent dynamics and short reaction times in a wide range of applications and installation situations."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default Pumps