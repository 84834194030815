import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../../Components/ImageText/ImageText'
import imgMark from '../../../../assets/mark.png';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../../Components/HfTabs/HfTabs';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/Stage.jpg'
import CetopDirectional from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/CetopDirectional.jpg'
import CetopProportional from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/CetopProportional.jpg'
import CetopPlate from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/CetopPlate.jpg'
import CetopValve from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/CetopValve.jpg'
import ReplacementSolenoidCoil from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/ReplacementSolenoidCoil.jpg'
import SealKits from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/SealKits.jpg'
import ScrewSets from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/ScrewSets.jpg'
import StandardisedCetop from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/StandardisedCetop.jpg'
import RobustDesign from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/RobustDesign.jpg'
import DiverseRange from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/DiverseRange.jpg'
import ValveTech from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/ValveTech.jpg'
import MadeToMeasure from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/MadeToMeasure.jpg'
import Purchase from '../../../../assets/Products/DriveAndControlTechnology/CetopValve/Purchase.jpg'





const CetopValves = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const CetopValve1 = {
        image: CetopDirectional,
        title: 'CETOP directional control valves of various types',
        description: 'CETOP directional control valves shut-off or divert the fluid flow to the various consumers in the hydraulic circuit. These binary switching valves are either completely open or closed and have no intermediate setting. HANSA-FLEX offers you a wide portfolio of CETOP directional control valves of different types:',
        listItems: ["Various switching arrangements: 4/2- and 4/3-way directional control valves", "Electrically, hydraulically, pneumatically or manually actuated", "Directly controlled or pilot controlled"],
        buttonName: "ALL DIRECTIONAL CONTROL VALVES"
    }

    const CetopValve2 = {
        image: CetopProportional,
        title: 'CETOP proportional valves for infinite flow adjustment',
        description: 'CETOP proportional valves are continuously adjustable valves and allow the infinite control of flow. They can be placed in any intermediate setting between open and closed, making them ideal for the complex hydraulic switching functions and electronic controls frequently found in modern systems. In the HANSA-FLEX online shop, you can find a number of proportional valves such as solenoid-operated directional control valves, pressure reducing and pressure relief valves.',
        buttonName: "ALL PROPORTIONAL VALVES"
    }

    const CetopValve3 = {
        image: CetopPlate,
        title: 'CETOP plate mounting valves for stacked valve arrangements',
        description: 'Several CETOP plate mounting valves with various different switching functions, such as directional and pressure control valves, shut-off and flow valves, can be combined in a stacked arrangement. Complex switching functions in hydraulic systems can be implemented in this space-saving and compact way.',
        listItems: ["Ready-for-installation plate mounting valves with screwed-in valves", "Large selection of various valve types", "Plate mounting valves in sizes NG 6, NG 10, NG 16 and NG 25"],
    }

    const CetopValve4 = {
        image: CetopValve,
        listItems: ["Shut-off, pressure, directional, changeover and proportional valves", "Electrically, hydraulically, pneumatically or manually actuated", "Connection base plates for up to 10 parallel valves", "Base plates optionally supplied with pressure limiting valve", "Reducer plates and cover plates", "Ready-to-install stacked valve arrangements to suit individual requirements"],
    }

    const CetopValve5 = {
        image: StandardisedCetop,
        title: 'Standardised CETOP valves in various nominal sizes (NG)',
        description: 'HANSA-FLEX directly supplies CETOP valves in nominal sizes NG 6, 10, 16 and 25. While we supply nominal size NG 6 valves for many applications requiring volume flows of up to 80 l/min, our range also contains NG 25 CETOP valves for controlling volume flows of up to 650 l/min, which are ideal for industrial applications with highly dynamic loadings such as occur on hydraulic presses. The two intermediate nominal sizes NG 10 and 16 complete the core part of the range with maximum flows of 140 l/min and 300 l/min respectively.',
    }

    const CetopValve6 = {
        image: RobustDesign,
        title: 'Robust design',
        description: 'All CETOP valves and intermediate plates from HANSA-FLEX are manufactured from robust cast steel with a corrosion-resistant coating. For applications with aggressive media, we also offer variants in stainless steel. Valves specially designed for use in potentially explosive atmospheres in accordance with the ATEX Directive complete the product range.',
        listItems: ["Material: high-grade cast steel or stainless steel", "Surface coating: burnished or phosphated", "Available in colours of the customer’s choice on request"],
    }

    const CetopValve7 = {
        image: DiverseRange,
        title: 'Diverse range of preassembled stacked valve arrangements',
        description: 'Stacking CETOP valves is a flexible way of combining a very wide range of control functions into a space-saving module. HANSA-FLEX configures and assembles stacked valve arrangements with all the required components such as valves, base plates, intermediate, reducer and cover plates to suit your specific requirements.',
        listItems: ["Illustration of complex hydraulic switching arrangements", "Ready-to-install stacked valve arrangements to suit your requirements", "Supply of power units with stacked valve arrangements"],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "First-class quality",
            description: "At HANSA-FLEX, you can find high-quality products from well-known manufacturers based in Europe and the USA",
            image: imgMark
        },
        {
            title: "Maximum availability",
            description: "At HANSA-FLEX, you receive the whole range of CETOP valves directly from our warehouse",
            image: imgMark
        },
        {
            title: "Custom solutions",
            description: "Advice, design and manufacture all under the same roof guarantees rapid implementation.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Replacement solenoid coils for solenoid-operated",
            description: "When the coil responsible for controlling a solenoid-operated valve stops working, it has to be replaced quickly. At HANSA-FLEX, you receive DC and AC coils in the shortest time possible directly from our warehouse.",
            listItems: ["Nominal voltages 12, 24 and 230 V, special voltages on request", "Designed for excellent switching performance", "Electric plugs available as accessories"],
            buttonName: "ALL SOLENOID COILS",
            image: ReplacementSolenoidCoil
        },
        {
            title: "Seals for all areas of use",
            description: "In addition to replaceable seals for CETOP valves, HANSA-FLEX also offer seals in other materials for special applications, such as with particularly hot or aggressive media.",
            listItems: ["Standard replacement seal sets in nitrile rubber (NBR)", "Temperature-resistant seals", "Seals for chemicals such as acids, alkalis and solvents"],
            buttonName: "ALL SEALS",
            image: SealKits
        },
        {
            title: "Screws for quick and reliable installation",
            description: "In our online shop, you can find a multitude of standardised hexagon socket screws and threaded rods for the assembly of stacked CETOP valve arrangements.",
            listItems: ["Standardised in accordance with DIN 912", "Can be shortened for different installation heights", "Available in numerous versions"],
            buttonName: "ALL SCREW SETS",
            image: ScrewSets
        },
    ];

    const tabTitles = [
        { title: "Replacement solenoid coils", eventKey: "Replacement solenoid coils" },
        { title: "Seal kits", eventKey: "Seal kits" },
        { title: "Screw sets", eventKey: "Screw sets" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"CETOP VALVES FOR COMPLEX HYDRAULIC CONTROLS"}
                    paragraph={"Even the most complex hydraulic controls can be incorporated using space-saving CETOP valves. With their standardised connection bores and plate mounting design, their great scope for combination with intermediate and collective line valve mounting plates, CETOP valves can be configured for a wide variety of switching functions in a relatively compact installation space."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PURCHASE PLATE MOUNTING VALVES ONLINE FROM THE FLUID TECHNOLOGY SPECIALISTS"}
                    subtitle={"As your one-stop partner, HANSA-FLEX not only has a large range of CETOP valves readily available, it also has experienced experts to give you comprehensive advice in the selection of suitable valve solutions."}
                />
                <ImageText
                    data={CetopValve1} />
                <ImageText
                    data={CetopValve2}
                    left={false} />
                <ImageText
                    data={CetopValve3} />
            </div>

            <div>
                <SectionHeader
                    title={"VALVES TO THE CETOP STANDARD FROM HANSA-FLEX"}
                    subtitle={"HANSA-FLEX offers a large selection of standardised CETOP valves with various switching functions and components for space-saving installation of complex hydraulic controls in stacked valve arrangements, all from the same supplier."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"THE CETOP VALVE PROGRAMME AT HANSA-FLEX"}
                    subtitle={"HANSA-FLEX has a large selection of CETOP valves with standardised connection hole patterns in accordance with ISO 4401 in four different nominal sizes (NG 6, NG 10, NG 16 and NG 25)."}
                />
                <ImageText
                    data={CetopValve4} />
            </div>

            <div>
                <SectionHeader
                    title={"SCREW-IN CARTRIDGE VALVES IN THE HANSA-FLEX ONLINE SHOP"}
                    subtitle={"HANSA-FLEX has an extensive range of screw-in cartridge valves for applications in mobile and stationary hydraulics. Find suitable valves for your required switching functions in our online shop. Our fluid technology experts are always delighted to advise you."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"ACCESSORIES FOR CETOP VALVES"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"ADVICE FROM THE FLUID EXPERTS"}
                    subtitle={"Which CETOP valves will allow you to achieve the required switching function in the most efficient way? And what is the best arrangement for the required stacked valves? Our experienced specialists advise you by telephone, e-mail or even directly on site so that you can find the most suitable solution."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA-FLEX HAS THE RIGHT VALVE FOR YOU"}
                    subtitle={"Impressive product quality combined with a huge choice and first-class service make us the leading fluid technology specialists in Europe."}
                />
                <ImageText
                    data={CetopValve5} />
                <ImageText
                    data={CetopValve6}
                    left={false} />
                <ImageText
                    data={CetopValve7} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE PRODUCTS AND SERVICES MAY ALSO BE OF INTEREST TO YOU"}
                    subtitle={"Other solutions for valve technology and hydraulic control available from HANSA-FLEX."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={ValveTech}
                                title="Valve technology for all fields of application"
                                desc={"With valve technology from HANSA-FLEX, you gain from high-grade materials, robust construction and long service life at attractive prices."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={MadeToMeasure}
                                title="Made-to-measure solutions"
                                desc={"HANSA-FLEX uses its knowledge and experience to manufacture your control components, such as valve blocks, stacked valve arrangements or complete hydraulic..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Purchase}
                                title="Purchase hydraulic pumps directly online"
                                desc={"We offer you a wide range of hydraulic pumps, such as gear wheel and axial piston pumps, for all fields of use in industrial and mobile hydraulics."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default CetopValves