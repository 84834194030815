import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/Stage.jpg'
import Chalenges from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/Chalenges.jpg'
import MaintainanceOfHydraulic from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/MaintainanceOfHydraulic.jpg'
import OperateSystems from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/OperateSystems.jpg'
import SimplyUnique from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/SimplyUnique.jpg'
import XCode from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/XCode.jpg'
import Support from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/Support.jpg'
import KeepOn from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/KeepOn.jpg'
import HydraulicHoseLine from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/HydraulicHoseLine.jpg'
import PressureAccumulator from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/PressureAccumulator.jpg'
import ndustrialHoses from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/ndustrialHoses.jpg'
import LiftingPlatform from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/LiftingPlatform.jpg'
import HFFluid from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/HFFluid.jpg'
import itInTheory from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/itInTheory.jpg'
import WeBring from '../../../assets/Services/PreventativeMaintainance/PreventativeMaintainance/WeBring.jpg'

const PreventataiveMaintainance = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: Chalenges,
        title: 'Challenges and advantages of preventive maintenance',
        description: 'Whether safety at work, system availability or cost savings, conservation of natural resources, energy efficiency or digitalisation: the requirements for maintenance today are as numerous as they are complex. System maintenance increasingly relies on proactive and forward-looking strategies to ensure as few malfunctions as possible in production processes. Systematically planning servicing and inspections as part of preventive maintenance can reduce the number of unplanned machine breakdowns and make an important contribution to overall success on the production floor.',
    }

    const data2 = {
        image: MaintainanceOfHydraulic,
        title: 'Preventive maintenance of hydraulic and other components',
        description: "The special characteristics of hydraulic components such as hose lines or pressure accumulators and the associated legally prescribed inspection and replacement intervals applying to them mean they are prime examples of equipment requiring professionally designed preventive maintenance plans. Benefit from the tools and know-how of HANSA-FLEX hydraulics specialists in the conception, implementation and the legally compliant, practical implementation of your individual maintenance strategy. Your machines will operate more reliably for longer and your team will have more time for dealing with unplanned events.",
    }

    const data3 = {
        image: OperateSystems,
        title: 'Operate systems and work equipment safely and in compliance with the law',
        description: 'Hydraulic hose lines have a limited service life and must be professionally inspected at regular intervals to ensure your machines and systems operate safely and in compliance with the law. The inspections must take place in accordance with the legally prescribed risk assessment in each case and be properly documented. We support you from A to Z in fulfilling these obligations with 360° hose management. This leaves you in a better position to concentrate on your core business.',
        buttonName: "HOSE MANAGEMENT"
    }

    const data4 = {
        image: KeepOn,
        description: "We inspect the following for you:",
        listItems: [
            "Hydraulic hose lines in accordance with DGUV and BetrSichV",
            "Industrial hose lines in accordance with TRBS 1201",
            "Pressure accumulators in accordance with the Pressure Equipment Directive (PED) and BetrSichV",
            "Lifting platforms and loading bridges in accordance with DGUV and BetrSichV"
        ]
    }

    //HfTab data
    const imageTextData = [
        {
            title: "Inspection of hydraulic hose lines in accordance",
            description: "We perform the legally prescribed inspections of your hydraulic hose lines in accordance with BetrSichV / DGUV for you and take care of the legally compliant documentation of the results. Inspections are carried out directly on site for you by officially authorised persons for inspecting hydraulic line equipment. Every inspection can be booked as an individual service or as part of our hose management package within our 360° hose management service.",
            image: HydraulicHoseLine,
            buttonName: "READ MORE"
        },
        {
            title: "Inspection of pressure accumulators in hydraulic",
            description: "Pressure or hydraulic accumulators are work equipment that can give rise to special risks. As pressurised containers, they are among the systems that need to be monitored in accordance with product safety legislation and are classified into one of four categories in accordance with the Pressure Equipment Directive (PED). We can perform the inspections required before hydraulic accumulators Category I are brought into use and the recurring inspections of hydraulic accumulators Categories I & II. An officially authorised person for pressure equipment with additional qualifications performs these inspections for you.",
            buttonName: "READ MORE",
            image: PressureAccumulator
        },
        {
            title: "Inspection of industrial hose lines in accordance",
            description: "We inspect all types of hose lines for chemicals, foodstuff, pharmaceuticals and other media. Inspections are carried out in our specialist centres for hose technology inspection or directly on site for you. For the mobile inspection service, an officially authorised person for inspections in accordance with TRBS 1203 in a fully equipped inspection vehicle performs all the prescribed inspections. Inspection certificates, identification label strips and inspection stickers can be produced on the vehicle and immediately applied.",
            buttonName: "READ MORE",
            image: ndustrialHoses
        },
        {
            title: "Inspection of lifting platforms in accordance",
            description: "We perform regular recurring inspections of lifting platforms and loading bridges in accordance with European standards DIN EN 1493, DIN EN 1494 and DIN EN 1495 for you. We also prepare all the legally required documentation of the inspections in accordance with of the German Industrial Safety Act (BetrSichV). Our service technicians are certified experts in lifting platforms in accordance with DGUV Rule 308-002 governing the inspection of lifting platforms. They perform the visual inspections and function tests in accordance with the requirements of GUV Rule R-500 “Operation of work equipment”.",
            buttonName: "READ MORE",
            image: LiftingPlatform
        },
    ];

    const tabTitles = [
        { title: "Hydraulic hose lines", eventKey: "Hydraulic hose lines" },
        { title: "Pressure accumulators", eventKey: "Pressure accumulators" },
        { title: "Industrial hoses", eventKey: "Industrial hoses" },
        { title: "Lifting platforms and loading bridges", eventKey: "Lifting platforms and loading bridges" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"AVOID RISKS, KEEP MACHINES MAINTAINED"}
                    paragraph={"Preventive maintenance helps avoid risks and harm while making your machines and systems last longer. HANSA-FLEX is a system maintainer and can support you with many worthwhile services. Benefit from our comprehensive services in the field of hose management of your hydraulic hose lines and in the performance and documentation of the legally prescribed inspections of various work equipment and systems by officially authorised persons."}
                />
            </div>

            <div>
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={"HOSE MANAGEMENT HANSA-FLEX STYLE"}
                    subtitle={"Professional hose management consists of several essential elements. Find out everything about our versatile hose coding system, unique hose management software and the services available from our specialist installation technicians."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={SimplyUnique}
                                title="Simply unique: hose identification with X-CODE"
                                desc={"A coding system tried and tested millions of times. One code contains all the information. Attached as a Tip Tag marker, self-adhesive label or wraparound ID plate, with QR code"} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={XCode}
                                title="X-CODE Manager, the easy-to-use hose management software"
                                desc={"Efficient software for all system maintainers. Benefit from the best overview of your hose lines, machines and servicing dates."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Support}
                                title="Support at a fixed price: hose management service package"
                                desc={"Planning certainty for a fixed daily flat rate. Discover our service packages for digitalisation, data maintenance, installation, inspections on site, and more."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <SectionHeader
                    title={'KEEP ON THE SAFE SIDE WITH HANSA-FLEX'}
                    subtitle={'Experienced specialists qualified as officially authorised persons support you with the inspection of your systems and work equipment in accordance with the currently applicable legal requirements. Benefit from our expertise and experience!'}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"INSPECTIONS OF EVERYTHING FROM HOSE LINES TO LIFTING PLATFORMS"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={HFFluid}
                                title="HANSA-FLEX Fluid Service reveals the secrets of your oil"
                                desc={"Many machine breakdowns can be traced to contaminants in hydraulic oil. Professional oil analysis by HANSA-FLEX reveals the possible causes of this in good time."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={itInTheory}
                                title="Fit in theory and in practice: seminars for system maintainers"
                                desc={"The International Hydraulics Academy (IHA) is Europe’s renowned provider of specialist hydraulics seminars and recognised certifications."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={WeBring}
                                title="We bring your machines back to life again"
                                desc={"Specialist service technicians support you on site with the repair and refurbishment of your hydraulic systems"} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default PreventataiveMaintainance