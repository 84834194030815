import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';


import imgStage from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/Stage.jpg'
import imgGerollerMotors from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/GerollerMotors.jpg'
import imgGerotor from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/Gerotor.jpg'
import imgAccessories from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/Accessories.jpg'
import imgSystemCompatibleMotor from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/SystemCompatibleMotor.jpg'
import imgTraining from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/Training.jpg'
import imgRepairAndOverhaul from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/RepairAndOverhaul.jpg'
import imgValveTech from '../../../assets/Products/DriveAndControlTechnology/HydraulicMotors/ValveTech.jpg'



const HydraulicMotor = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const motor1 = {
        image: imgGerollerMotors,
        title: 'Geroller motors work reliably under pressure',
        description: 'HANSA-FLEX geroller motors are a dependable way to provide low drive speeds without the need for additional gears. Geroller motors are used when high torques are required in a small installation space.',
        listItems: ["Large displacement volume from relatively small dimensions", "Speed: 50 to 800 rpm, torque: 240 to 850 Nm", "High efficiency even under continuous high pressure"],
        buttonName: "ALL GEROLLER MOTORS"
    }

    const motor2 = {
        image: imgGerotor,
        title: 'Gerotor motor are low maintenance, robust and versatile in use',
        description: 'Gerotor motors from HANSA-FLEX excel through their long service life. The compact motors find use in situations where high rotational speeds are required.',
        listItems: ["High rotational speeds combined with compact dimensions", "Speed: 240 to 1,900 rpm, torque: 11 to 380 Nm", "Suitable for a diverse range of hydraulic fluids"],
        buttonName: "ALL GEROTOR MOTOR"
    }

    const motor3 = {
        image: imgAccessories,
        title: 'Accessories and spare parts - all from a single provider',
        description: 'Prompt replacement of wear parts and accessories ensures a long service life for your hydraulic motors and reduced standstill times for servicing and maintenance.',
        listItems: ["Shock valve technology for direct flange mounting", "Special seal sets for the smallest motors", "Flanges for gerotor motors"],
        buttonName: "TO THE ACCESSORIES"
    }

    const motor4 = {
        image: imgSystemCompatibleMotor,
        description: 'Orbital motors are suitable for a broad field of applications in mobile hydraulics such as snowploughs, construction plant, agricultural machines and plastic injection moulding machines.',
        listItems: ["Extensive product portfolio of hydraulic motors", "Premium products from Europe or price-conscious economy variants", "Shafts of various diameters (16, 25, 32 and 40 mm)", "Four- or two-hole flange with high-pressure seal"],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Proven quality",
            description: "At HANSA-FLEX you can count on receiving durable hydraulic motors, proven in practice time and time again.",
            image: imgMark
        },
        {
            title: "High-grade materials",
            description: "Robust cast steel in a compact design, special surface coatings on request.",
            image: imgMark
        },
        {
            title: "Advice from hydraulics experts",
            description: "Our hydraulics specialists advise you on all questions, drawing on their comprehensive know-how.",
            image: imgMark
        },
        {
            title: "Excellent parts availability",
            description: "Our warehouse and logistics concept can supply motors and accessories on a Just-In-Time basis.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"THE RIGHT HANSA-FLEX HYDRAULIC MOTOR FOR YOUR APPLICATION"}
                    paragraph={"Hydraulic motors transform hydraulic energy into mechanical energy. We offer a wide range of geroller and gerotor motors matched to the performance data of your machines and systems in proven quality as premium or economy variants for numerous applications in mobile and industrial hydraulics. We can provide other types of hydraulic motors such as axial, gear and radial piston motors on request."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PLANETARY MOTORS AND SUITABLE ACCESSORIES FROM A SYSTEM SUPPLIER"}
                />
                <ImageText
                    data={motor1} />
                <ImageText
                    data={motor2}
                    left={false} />
                <ImageText
                    data={motor3} />
            </div>

            <div>
                <SectionHeader
                    title={"SPECIAL SOLUTIONS PERFECT FOR YOUR APPLICATION"}
                    subtitle={"HANSA-FLEX supplies hydraulic motors configured for your applications with special valves, shafts, oil outlets for various fields of use and axial, gear and radial piston motors. All our hydraulic motors can also be supplied ready for installation and painted to suit the customer’s requirements on request."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PERFORMANCE ADVANTAGES OF HANSA-FLEX HYDRAULIC MOTORS"}
                    subtitle={"Extensive product portfolio of hydraulic motors with various torques and speed ranges."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"SYSTEM-COMPATIBLE HYDRAULIC MOTORS FROM HANSA-FLEX"}
                    subtitle={"HANSA-FLEX planetary motors excel through their high torques and speed ranges."}
                />
                <ImageText
                    data={motor4} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgTraining}
                                title="Training from the experts"
                                desc={"In cooperation with the International Hydraulics Academy (IHA), HANSA-FLEX offers a wide range of courses and seminars on fluid technology."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgRepairAndOverhaul}
                                title="Repair and overhaul of hydraulic components"
                                desc={"HANSA-FLEX experts can repair and overhaul motors, pumps and cylinders of all sizes and from any manufacturer."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgValveTech}
                                title="Valve technology for pressures up to 350 bar"
                                desc={"With high-quality valves from HANSA-FLEX, you benefit from excellent dynamics and short reaction times in a wide range of applications and installation situations."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default HydraulicMotor